import { useEffect, useState } from "react";
import "../styles/Table.css";
import "../styles/FoldersTable.css";
import { Link } from "react-router-dom";
import {
   deleteFolderService,
   getAllFolderService,
   updateFolderService,
} from "../services/FolderService";
import FormFolder from "./Owner/FormFolder";
import Folder from "../assets/Folder.png";
import { ViewDollar } from "../utils";
import { Button, Modal } from "react-bootstrap";
import Edit from "../assets/edit.png";
import Save from "../assets/save.png";
import Delete from "../assets/delete.png";
import { useDispatch } from "react-redux";
import { setGrantCurrent } from "../redux/Reducers/roleSlice";

function FoldersTable({ column1, column2, column3 }) {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [content, setContent] = useState([]);
   const [showDelete, setShowDelete] = useState(false);
   const [idDelete, setIdDelete] = useState(undefined);

   const [isEditing, setIsEditing] = useState(null);
   const [order, setOrder] = useState({ col: null, type: null });
   const dispatch = useDispatch();

   useEffect(() => {
      getAllFolder();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   async function getAllFolder() {
      dispatch(setGrantCurrent(undefined));
      setContent(await getAllFolderService());
   }

   const handleEdit = (eve, index) => {
      eve.preventDefault();
      setIsEditing(index);
   };

   const handleSave = async (eve, index) => {
      const editedData = content[index];

      try {
         await updateFolderService(editedData.id, editedData);
         setIsEditing(null);
      } catch (error) {
         console.log(error);
      }
   };

   const handleDelete = (eve, index) => {
      eve.preventDefault();
      setShowDelete(true);
      setIdDelete(content[index]);
   };

   const handleInputChange = (eve, index) => {
      const { name, value } = eve.target;
      setContent((prevContent) =>
         prevContent.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
         )
      );
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof content[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...content].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setContent(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   return (
      <>
         <div className="tableWrapper tableWrapper--folders mx-auto">
            <div className="mt-4">
               <div className="primarybar fw-normal fs-3 px-4 pe-md-4 ps-md-5 fw-semibold p-0 align-items-center d-flex justify-content-between">
                  Folders
                  <div className="contractamount fw-normal d-flex me-4 m-md-5">
                     <button
                        type="button"
                        className="addBtn btn btn-lg  ms-4 m-auto"
                        onClick={handleShow}
                     >
                        +
                     </button>
                  </div>
               </div>
               <div className="dataTable dataTable--folders dataTable-container mx-auto">
                  <div className="list-container list-container--folders overflow-auto">
                     <div className="rowItem row-container row-container--folders px-4 fw-bold align-items-center">
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("name")}
                        >
                           {column1} {getSortingIndicator("name")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("total_amount_from_grants")}
                        >
                           {column2}{" "}
                           {getSortingIndicator("total_amount_from_grants")}
                        </div>
                        <div className="attribute attribute-update my-auto ps-2">
                           Update
                        </div>
                     </div>

                     {content.map((f, index) => (
                        <div
                           key={index}
                           className="rowItem row-container row-container--folders px-4 align-items-center"
                        >
                           {isEditing === index ? (
                              <div className="attribute" data-name={column1}>
                                 <input
                                    className="input"
                                    type="text"
                                    value={f.name}
                                    name="name"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div
                                 className="attribute attribute--folder"
                                 data-name={column1}
                              >
                                 <Link
                                    to={`/folder/${f.id}/projects`}
                                    className="d-flex align-items-center"
                                 >
                                    <img
                                       className="icon ps-1 pe-2"
                                       src={Folder}
                                       alt="icon-Folder"
                                    ></img>
                                    <div className="text-dark">{f.name}</div>
                                 </Link>
                              </div>
                           )}
                           <div
                              className="attribute attribute--folder"
                              data-name={column2}
                           >
                              {ViewDollar(f.total_amount_from_grants)}
                           </div>

                           <div
                              className="attribute d-md-flex ps-md-2"
                              data-name="Update"
                           >
                              <span className="d-flex">
                                 {isEditing === index ? (
                                    <button
                                       className="btn update-button--save d-flex p-1"
                                       onClick={(eve) => handleSave(eve, index)}
                                    >
                                       <img
                                          className="icon-update"
                                          src={Save}
                                          alt="icon-update"
                                       ></img>
                                    </button>
                                 ) : (
                                    <button
                                       className="btn update-button d-flex p-1 align-items-center"
                                       onClick={(eve) => handleEdit(eve, index)}
                                    >
                                       <img
                                          className="icon-update"
                                          src={Edit}
                                          alt="icon-update"
                                       ></img>
                                    </button>
                                 )}
                                 <button
                                    className="btn update-button d-flex p-1 ms-1 align-items-center"
                                    onClick={(eve) => handleDelete(eve, index)}
                                 >
                                    <img
                                       className="icon-update"
                                       src={Delete}
                                       alt="icon-update"
                                    ></img>
                                 </button>
                              </span>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>

         {/* Modal Delete Start */}
         <Modal
            centered
            size="lg"
            show={showDelete}
            onHide={() => setShowDelete(false)}
         >
            <Modal.Header closeButton>
               <Modal.Title>Warning </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <p className="m-0">
                  {" "}
                  Are you sure you want to delete this FOLDER?{" "}
               </p>
               <p className="m-0">{idDelete && `Name: ${idDelete.name}`}</p>
               <p className="m-0">
                  {idDelete &&
                     `Total Amount: ${ViewDollar(
                        idDelete.total_amount_from_grants
                     )}`}
               </p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowDelete(false)}>
                  Close
               </Button>
               <Button
                  variant="danger"
                  onClick={async () => {
                     await deleteFolderService(idDelete.id);
                     getAllFolder();
                     setShowDelete(false);
                  }}
               >
                  Delete
               </Button>
            </Modal.Footer>
         </Modal>
         {/* Modal Delete End */}

         <FormFolder
            show={show}
            handleClose={handleClose}
            getAllFolders={getAllFolder}
         />
      </>
   );
}

export { FoldersTable };
