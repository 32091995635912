import { Layout } from "../components/Layout";
import { AdminContractTable } from "../components/AdminContractTable";
import { useState } from "react";
// import "../styles/ContractTable.css";

function AdminContractDetails() {
   document.title = "Contract Details – Envision Education";
   const [percentage, setPercentage] = useState(0);

   const handleCallback = (childData) => {
      setPercentage(childData);
   };
   return (
      <>
         <Layout percentage={percentage}>
            <AdminContractTable
               column1="Contract / PO"
               column2="Company / Name"
               column3="Department / Program"
               column4="Contract Start"
               column5="Contract End"
               column6="Contract Amount"
               parentCallback={handleCallback}
            />
         </Layout>
      </>
   );
}

export { AdminContractDetails };
