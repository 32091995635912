import { useEffect, useState } from "react";
import "../styles/Table.css";
import "../styles/ContractTable.css";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
   deleteContractService,
   getAllContractByBudgetService,
   getNoteByContractService,
   getSummaryContractService,
   updateContractService,
   updateNoteByContractoService,
} from "../services/ContractServices";
import FormContract from "./FormContract";
import { getBudgetByIdService } from "../services/BudgetService";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { ViewDateYear, ViewDollar } from "../utils";
import { DynamicText } from "./DynamicText";
import Edit from "../assets/edit.png";
import Save from "../assets/save.png";
import Delete from "../assets/delete.png";
import Notes from "../assets/notes.png";
import Stats from "../assets/stats.png";
import {
   getTotalEncumbranceService,
   getTotalOutstandingService,
} from "../services/EncumbrancesServices";
import { useDispatch, useSelector } from "react-redux";
import { setContractCurrent } from "../redux/Reducers/roleSlice";
import { toast } from "react-hot-toast";

function AdminContractTable({
   column1,
   column2,
   column3,
   column4,
   column5,
   column6,
   parentCallback,
}) {
   const contractCurrent = useSelector((state) => state.role.contractCurrent);
   const [budgetCurrent, setBudgetCurrent] = useState();
   const [show, setShow] = useState(false);
   const [showNote, setShowNote] = useState(false);
   const [showDelete, setShowDelete] = useState(false);
   const [idDelete, setIdDelete] = useState(undefined);
   const [noteSelectec, setNoteSelectec] = useState("");
   const [idContractNodeUp, setIdContractNodeUp] = useState(undefined);
   const [messageNote, setMessageNote] = useState(undefined);
   const [showAlert, setShowAlert] = useState(false);
   const [totalEncumbraces, settotalEncumbraces] = useState(undefined);
   const [totalOutstanding, setTotalOutstanding] = useState(undefined);
   const { folder_id, id } = useParams();

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [content, setContent] = useState([]);
   const [isEditing, setIsEditing] = useState(null);
   const [order, setOrder] = useState({ col: null, type: null });

   const dispatch = useDispatch();

   useEffect(() => {
      getAllContractsByBudget(id);
   }, [id]);

   /*   useEffect(() => {
    if (grantCurrent) {
      parentCallback(grantCurrent.total_remaining_percentage);
    }
  }, [parentCallback, grantCurrent]); */

   const getAllContractsByBudget = async (id) => {
      const array = await getAllContractByBudgetService(id);
      const promisesArray = array.map(async (c) => {
         try {
            const object = await (await getSummaryContractService(c.id)).data;
            return Object.assign(c, object);
         } catch (error) {
            console.log(error);
         }
      });
      const result = await Promise.all(promisesArray);
      setContent(result);

      setBudgetCurrent(await getBudgetByIdService(id));
      // settotalEncumbraces(await getTotalEncumbranceService(id));
      // setTotalOutstanding(await getTotalOutstandingService(id));
   };

   if (budgetCurrent) {
      dispatch(setContractCurrent(budgetCurrent.remaining_percentage));
   }

   const handleEdit = (eve, index) => {
      eve.preventDefault();
      setIsEditing(index);
   };

   const handleSave = async (eve, index) => {
      eve.preventDefault();
      const editedData = content[index];

      try {
         await updateContractService(editedData.id, editedData);
         setIsEditing(null);
         toast.success("Updated Contract");
         getAllContractsByBudget(id);
      } catch (error) {
         if (error.response.status === 422) {
            error.response.data.error.map((c) => toast.error(c));
         }
      }
   };

   const handleDelete = (eve, index) => {
      eve.preventDefault();
      setShowDelete(true);
      setIdDelete(content[index].id);
      //setContent(content.filter((_, i) => i !== index));
   };

   const handleInputChange = (eve, index) => {
      const { name, value } = eve.target;
      setContent((prevContent) =>
         prevContent.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
         )
      );
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof content[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...content].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setContent(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   const getNoteSelect = async (index) => {
      console.log(content[index]);
      setNoteSelectec(await getNoteByContractService(content[index].id));
      setIdContractNodeUp(content[index].id);
      setShowNote(true);
   };

   const updateNodeContract = async () => {
      setMessageNote(undefined);
      try {
         await updateNoteByContractoService(idContractNodeUp, noteSelectec);
         setShowAlert(true);
         setMessageNote("Note has been successfully updated");
         getAllContractsByBudget(id);
      } catch (error) {
         console.log(console.error());
      }
   };

   return (
      <>
         <div className="tableWrapper mx-auto">
            <div className="mt-4">
               <div className="primarybar fs-3 px-4 pe-md-4 ps-md-5 fw-semibold p-0 align-items-center d-flex justify-content-between">
                  <h3 className="budgetName m-auto m-md-0 p-1 w-100">
                     {" "}
                     {budgetCurrent && (
                        <DynamicText
                           text={`Contracts / ${budgetCurrent.object} - ${budgetCurrent.function} / ${budgetCurrent.description}`}
                        />
                     )}
                  </h3>
                  <div className="contractamount fw-normal d-flex me-4 m-md-5">
                     <button
                        type="button"
                        className="addBtn btn btn-lg  ms-4 m-auto"
                        onClick={handleShow}
                     >
                        +
                     </button>
                  </div>
               </div>
               <div className="dataTable dataTable-container mx-auto position-relative">
                  <div className="theadwrapper p-0">
                     <div className="secondbar text-center py-2 fw-bold d-flex flex-column px-4 align-items-center flex-md-row">
                        <p className="p-1 m-0 mt-md-1 fs-5 text-white">
                           <div className="d-block d-md-inline">Contract:</div>
                           {budgetCurrent && (
                              <span className="contract--n contr p-2">
                                 {" "}
                                 {budgetCurrent &&
                                    ViewDollar(budgetCurrent.contract_amount)}
                              </span>
                           )}
                        </p>
                        <p className="p-1 m-0 mt-1 fs-5 text-white">
                           <div className="d-block d-md-inline">
                              T. Remaining:
                           </div>
                           {budgetCurrent && (
                              <span className="contract--n rema p-2">
                                 {" "}
                                 {budgetCurrent &&
                                    `${ViewDollar(
                                       budgetCurrent.total_contract_assigned_remaining
                                    )}`}
                              </span>
                           )}
                        </p>
                        <p className="p-1 m-0 mt-md-1 fs-5 text-white d-none d-md-inline">
                           <div className="d-md-inline">T. Expenses:</div>

                           {budgetCurrent && (
                              <span className="contract--n expen p-2">
                                 {" "}
                                 {budgetCurrent &&
                                    `${ViewDollar(budgetCurrent.expenses)}`}
                              </span>
                           )}
                        </p>

                        <div className="contractamount contractamount--encumbrance fw-normal d-flex align-items-center m-md-0 mt-3">
                           <Link
                              to={`/folder/${folder_id}/projects/encumbrance/${
                                 budgetCurrent && budgetCurrent.id
                              }`}
                           >
                              <Button
                                 variant="none"
                                 className="w-100 px-4 text-white fw-bold fs-md-5 px-md-4 btn-expenses"
                                 // variant="warning"
                                 style={{ backgroundColor: "var(--yellow)" }}
                              >
                                 Expenses
                              </Button>
                           </Link>
                        </div>
                     </div>
                     {/* <div className="bg-blue text-center py-2 px-2 text-white fw-bold position-relative"> */}
                     {/* </div> */}
                  </div>
                  <div className="list-container list-container--contract overflow-auto">
                     <div className="rowItem row-container row-container--contract px-4 fw-bold">
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_po")}
                        >
                           {column1} {getSortingIndicator("contract_po")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("company_name")}
                        >
                           {column2} {getSortingIndicator("company_name")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("department_program")}
                        >
                           {column3} {getSortingIndicator("department_program")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_start_date")}
                        >
                           {column4}{" "}
                           {getSortingIndicator("contract_start_date")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_end_date")}
                        >
                           {column5} {getSortingIndicator("contract_end_date")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_amount")}
                        >
                           {column6} {getSortingIndicator("contract_amount")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("remaining_budget")}
                        >
                           Remaining {getSortingIndicator("remaining_budget")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("encumbrances_total")}
                        >
                           Expenses {getSortingIndicator("encumbrances_total")}
                        </div>
                        <div className="attribute attribute-update my-auto">
                           View
                        </div>
                        <div className="attribute attribute-update my-auto ps-2">
                           Update
                        </div>
                     </div>

                     {content.map((c, index) => (
                        <form
                           key={index}
                           className="rowItem row-container row-container--contract px-4 align-items-center"
                        >
                           {isEditing === index ? (
                              <div
                                 key={c.id}
                                 className="attribute"
                                 data-name={column1}
                              >
                                 <input
                                    className="input"
                                    type="text"
                                    value={c.contract_po}
                                    name="contract_po"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column1}>
                                 {c.contract_po}
                              </div>
                           )}
                           {isEditing === index ? (
                              <div className="attribute" data-name={column2}>
                                 <input
                                    className="input"
                                    type="text"
                                    value={c.company_name}
                                    name="company_name"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column2}>
                                 {c.company_name}
                              </div>
                           )}
                           {isEditing === index ? (
                              <div className="attribute" data-name={column3}>
                                 <input
                                    className="input"
                                    type="text"
                                    value={c.department_program}
                                    name="department_program"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column3}>
                                 {c.department_program}
                              </div>
                           )}
                           {isEditing === index ? (
                              <div className="attribute" data-name={column4}>
                                 <input
                                    className="input"
                                    type="date"
                                    value={c.contract_start_date}
                                    name="contract_start_date"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column4}>
                                 {c.contract_start_date &&
                                    ViewDateYear(c.contract_start_date)}
                              </div>
                           )}
                           {isEditing === index ? (
                              <div className="attribute" data-name={column4}>
                                 <input
                                    className="input"
                                    type="date"
                                    value={c.contract_end_date}
                                    name="contract_end_date"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column4}>
                                 {c.contract_end_date &&
                                    ViewDateYear(c.contract_end_date)}
                              </div>
                           )}
                           {isEditing === index ? (
                              <div
                                 data-name={column5}
                                 className="attribute position-relative"
                              >
                                 <span className="input-dollar--sign">$</span>
                                 <input
                                    className="input input-dollar"
                                    type="number"
                                    value={c.contract_amount}
                                    name="contract_amount"
                                    onChange={(e) =>
                                       handleInputChange(e, index)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="attribute" data-name={column5}>
                                 {ViewDollar(c.contract_amount)}
                              </div>
                           )}

                           <div className="attribute" data-name={column4}>
                              {c.remaining_budget &&
                                 ViewDollar(c.remaining_budget)}
                           </div>

                           <div className="attribute" data-name={column4}>
                              {c.encumbrances_total &&
                                 ViewDollar(c.encumbrances_total)}
                           </div>
                           <div
                              className="attribute d-md-flex ps-md-2"
                              data-name="View"
                           >
                              <span className="d-flex">
                                 <button
                                    type="button"
                                    className={`btn d-flex p-1 align-items-center ${
                                       c.notes !== ""
                                          ? "update-button-green"
                                          : "update-button"
                                    }`}
                                    onClick={() => getNoteSelect(index)}
                                 >
                                    <img
                                       className="icon-update"
                                       alt="icon-update"
                                       src={Notes}
                                    ></img>
                                 </button>
                                 <Link
                                    to={`/folder/${folder_id}/projects/contract/graphic/${c.id}
                                 `}
                                    className="btn update-button d-flex p-1 align-items-center ms-1"
                                 >
                                    <img
                                       className="icon-update"
                                       alt="icon-update"
                                       src={Stats}
                                    ></img>
                                 </Link>
                              </span>
                           </div>
                           <div
                              className="attribute d-md-flex ps-md-2"
                              data-name="Update"
                           >
                              <span className="d-flex">
                                 {isEditing === index ? (
                                    <button
                                       className="btn update-button--save d-flex p-1"
                                       onClick={(eve) => handleSave(eve, index)}
                                    >
                                       <img
                                          className="icon-update"
                                          src={Save}
                                          alt="icon-update"
                                       ></img>
                                    </button>
                                 ) : (
                                    <button
                                       className="btn update-button d-flex p-1 align-items-center"
                                       onClick={(eve) => handleEdit(eve, index)}
                                    >
                                       <img
                                          className="icon-update"
                                          src={Edit}
                                          alt="icon-update"
                                       ></img>
                                    </button>
                                 )}
                                 <button
                                    className="btn update-button d-flex p-1 ms-1 align-items-center"
                                    onClick={(eve) => handleDelete(eve, index)}
                                 >
                                    <img
                                       className="icon-update"
                                       src={Delete}
                                       alt="icon-update"
                                    ></img>
                                 </button>
                              </span>
                           </div>
                        </form>
                     ))}
                     {/* <div
                        className="separator--contract"
                        style={{ background: "none" }}
                     ></div> */}
                  </div>
               </div>
            </div>

            {/* Modal Notes Start */}
            <Modal
               centered
               size="lg"
               show={showNote}
               onHide={() => setShowNote(false)}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Notes </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {messageNote && (
                     <Alert
                        show={showAlert}
                        dismissible
                        variant="success"
                        onClose={() => setShowAlert(false)}
                     >
                        {messageNote}
                     </Alert>
                  )}
                  <div className="mb-3">
                     <Form.Control
                        as="textarea"
                        name="notes"
                        id="notes"
                        placeholder=""
                        value={noteSelectec}
                        onChange={(e) => setNoteSelectec(e.target.value)}
                        rows={5}
                     />
                  </div>
                  <Button
                     variant="success"
                     className="w-100 py-2"
                     onClick={updateNodeContract}
                  >
                     Save
                  </Button>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowNote(false)}
                  >
                     Close
                  </Button>
               </Modal.Footer>
            </Modal>
            {/* Modal Notes Start */}

            {/* Modal Delete Start */}
            <Modal
               centered
               size="lg"
               show={showDelete}
               onHide={() => setShowDelete(false)}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Warning </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <p>
                     Are you sure you want to delete this contract?{" "}
                     {idDelete && idDelete}
                  </p>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowDelete(false)}
                  >
                     Close
                  </Button>
                  <Button
                     variant="danger"
                     onClick={async () => {
                        try {
                           await deleteContractService(idDelete);
                           setShowDelete(false);
                           getAllContractsByBudget(id);
                        } catch (error) {
                           console.log(error);
                        }
                     }}
                  >
                     Delete
                  </Button>
               </Modal.Footer>
            </Modal>
            {/* Modal Delete End */}

            <FormContract
               show={show}
               handleCloseModal={handleClose}
               budget_id={id}
               getAllContractsByBudget={getAllContractsByBudget}
            />
         </div>
      </>
   );
}

export { AdminContractTable };
