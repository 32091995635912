import axios from "axios";

export const getAllContractByBudgetService = async (id_budget) => {
  try {
    const result = await axios.get("contracts/" + id_budget);
    const parsedData = result.data.map((contract) => ({
      ...contract,
      contract_amount: parseFloat(contract.contract_amount).toFixed(2),
    }));
    return parsedData;
  } catch (error) {
    console.log(error);
  }
};

export const createContractService = async (data) => {
  return await axios.post("/contracts", data);
};

export const updateContractService = async (id, editedData) => {
  try {
    const result = await axios.patch(`/contracts/${id}`, editedData);
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteContractService = async (id) => {
  return axios.delete(`/contracts/${id}`);
};

export const getNoteByContractService = async (id) => {
  try {
    const result = await axios.get(`/contracts/notes/${id}`);
    console.log(result.data);
    return result.data.notes;
  } catch (error) {
    console.log(error);
  }
};

export const updateNoteByContractoService = async (id, note) => {
  const result = await axios.patch(`/contracts/${id}`, {
    contract: { notes: note },
  });
  return result;
};
export const getSummaryContractService = async (id) => {
  return await axios.get(`/contracts/${id}/summary`);
};
