import logo from "../assets/logo_bts.png";
import { Link } from "react-router-dom";
import { GoBackButton } from "./GoBackButton";
import { AccountButton } from "./AccountButton";
import "../styles/Header.css";
import { UserCurrentCard } from "./UserCurrentCard";
import BatteryGauge from "react-battery-gauge";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";

function Header({ goBackBtn, budgetBar, currentUser, accountBtn, percentage }) {
   const grantCurrent = useSelector((state) => state.role.grantCurrent);
   const contractCurrent = useSelector((state) => state.role.contractCurrent);
   const [gaugeValue, setGaugeValue] = useState(0);
   const { folder_id, id } = useParams();
   const [show, setShow] = useState(false);

   // const [battery, setBattery] = useState(0);
   console.log(
      useSelector((store) => {
         console.log(store);
      })
   );

   useEffect(() => {
      if (folder_id && !id) {
         setGaugeValue(grantCurrent && grantCurrent.total_remaining_percentage);
         if (grantCurrent) {
            setShow(true);
         }
      } else {
         setShow(true);
         setGaugeValue(
            !id
               ? grantCurrent && grantCurrent.total_remaining_percentage
                  ? grantCurrent.total_remaining_percentage
                  : contractCurrent
               : contractCurrent
         );
      }
   }, [id, grantCurrent, contractCurrent, folder_id]);

   // useEffect(() => {
   //    if (grantCurrent) {
   //       setBattery(grantCurrent.total_remaining_percentage);
   //    }
   // }, [grantCurrent]);

   // useEffect(() => {
   //    if (contractCurrent) {
   //       setBattery(contractCurrent);
   //    }
   // }, [contractCurrent]);

   // console.log(battery);

   return (
      <>
         {/* <header> */}
         <header className="navbar pt-4 pt-md-5">
            {!goBackBtn && <GoBackButton />}
            <div className="d-flex align-items-center justify-content-center m-auto mt-4">
               <span className="d-flex mb-0 h1 d-flex align-items-center m-auto">
                  <Link to="/">
                     <img
                        src={logo}
                        alt="Envision Education Logo"
                        className="logo d-inline-block align-text-top d-flex align-items-center m-auto"
                     />
                  </Link>
               </span>

               {show &&
                  folder_id &&
                  folder_id &&
                  (grantCurrent != undefined ||
                     contractCurrent != undefined) && (
                     <>
                        <div className="d-flex flex-column align-items-center">
                           <BatteryGauge
                              className="budget-battery ms-4 ms-md-5"
                              value={gaugeValue}
                              // value={folder_id && !id && grantCurrent & grantCurrent}
                              aspectRatio={0.36}
                              animated={true}
                              customization={{
                                 batteryBody: {
                                    strokeColor: "var(--secondary-color)",
                                 },
                                 batteryCap: {
                                    strokeColor: "var(--secondary-color)",
                                 },
                                 batteryMeter: {
                                    fill: "var(--green)",
                                    lowBatteryFill: "var(--red)",
                                    outerGap: 2.4,
                                    interCellsGap: 1.5,
                                 },
                                 readingText: {
                                    darkContrastColor: "var(--global-font)",
                                    lightContrastColor:
                                       "var(--secondary-color)",
                                    lowBatteryColor: "var(--red)",
                                 },
                              }}
                           />
                        </div>
                     </>
                  )}

               {/* <span className="navbar-brand mb-0 h1">
                  <img
                     className="BTS-logo d-inline-block align-text-top"
                     src={logo_bts}
                     alt="Logo"
                  />
               </span> */}
               {!currentUser && <UserCurrentCard />}
            </div>
            {!accountBtn && <AccountButton />}
         </header>
         {/* </header> */}
      </>
   );
}

export { Header };
