import "./global.css";
import "bootstrap";
import { AdminContractDetails } from "./pages/AdminContractDetails";
import { UserContractDetails } from "./pages/UserContractDetails";
import { AdminEncumbrance } from "./pages/AdminEncumbrance";
import { UserEncumbrance } from "./pages/UserEncumbrance";
import { Account } from "./pages/Account";
import { Switch, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { AdminFolders } from "./pages/AdminFolders";
import { AdminProjects } from "./pages/AdminProjects";
import { UserProjects } from "./pages/UserProjects";
import EncumbranceCharPage from "./pages/EncumbrancesChar";
import ContractDetailsCharBar from "./pages/ContractDetailsCharBar";
/* import { useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { useState } from "react"; */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRole } from "./redux/Reducers/roleSlice";
import { getCurrentUserService } from "./services/UsersServices";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { OwnerProjects } from "./pages/OwnerProjects";
import { ReportByGrant } from "./pages/ReportByGrant";
import { Toaster } from "react-hot-toast";

function App() {
   const dispatch = useDispatch();
   const userRole = useSelector((state) => state.role.role);
   const folderId = useSelector((state) => state.role.folderId);

   useEffect(() => {
      setRoleForToken();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const setRoleForToken = async () => {
      const token = localStorage.getItem("token");
      const user_result = await getCurrentUserService(token);
      if (user_result) {
         dispatch(setRole(user_result.role));
      }
   };

   return (
      <div className="App">
         <Switch>
            <Route path="/account" component={Account} />

            <Route path="/report/:grant_id" component={ReportByGrant} />

            <Route
               path="/folder/:folder_id/projects/encumbrance/graphic/:id"
               component={EncumbranceCharPage}
            />

            <Route
               path="/folder/:folder_id/projects/encumbrance/:id"
               component={
                  userRole === "User" ? UserEncumbrance : AdminEncumbrance
               }
            />

            <Route
               exact
               path="/"
               render={() =>
                  userRole === "Owner" ? (
                     <AdminFolders />
                  ) : userRole === "User" || userRole === "Admin" ? (
                     <Redirect to={`/folder/${folderId}/projects`} />
                  ) : (
                     <Redirect to="/login" />
                  )
               }
            />

            <Route
               path="/folder/:folder_id/projects/contract/graphic/:id"
               component={ContractDetailsCharBar}
            />

            <Route
               path="/folder/:folder_id/projects/contract/:id"
               component={
                  userRole === "User"
                     ? UserContractDetails
                     : AdminContractDetails
               }
            />

            <Route
               exact
               path="/folder/:folder_id/projects"
               component={
                  userRole === "User"
                     ? UserProjects
                     : userRole === "Owner"
                     ? OwnerProjects
                     : AdminProjects
               }
            />

            <Route exact path="/" component={Login} />

            <Route path="/*" component={Login} />
         </Switch>

         <Toaster position="top-center" reverseOrder={false} />
      </div>
   );
}

export default App;
