import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCurrentUserService } from "../services/UsersServices";

export default function InformationPersonal() {
   const [userInformation, setUserInformation] = useState(undefined);
   const {
      handleSubmit,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      console.log(data);
   };

   useEffect(() => {
      getCurrentUser();
   }, []);

   const getCurrentUser = async () => {
      const token = localStorage.getItem("token");
      setUserInformation(await getCurrentUserService(token));
   };

   return (
      <>
         <form
            onSubmit={handleSubmit(onSubmit)}
            className="text-start p-4"
            id="user-form"
         >
            {userInformation && (
               <div className="mb-3">
                  <span className="fw-semibold">Full Name: </span>
                  <i> {userInformation.name}</i>
               </div>
            )}
            {userInformation && (
               <div className="mb-3">
                  <span className="fw-semibold">Email Address: </span>
                  <i> {userInformation.email}</i>
               </div>
            )}
            {userInformation && (
               <div className="mb-3">
                  <span className="fw-semibold">Since: </span>
                  <i> {userInformation.created_date}</i>
                  {errors.role && (
                     <span className="text-danger">This field is required</span>
                  )}
               </div>
            )}
            {userInformation && (
               <div className="mb-3">
                  <span className="fw-semibold">Role: </span>
                  <i> {userInformation.role}</i>
                  {errors.role && (
                     <span className="text-danger">This field is required</span>
                  )}
               </div>
            )}
         </form>
      </>
   );
}
