import { useEffect, useState } from "react";
import { Switch, Route, NavLink, useLocation } from "react-router-dom";
import "../styles/AccountTable.css";
import "../styles/Table.css";

import InformationPersonal from "./InformationPersonal";
import { useSelector } from "react-redux";
import ListUserAccount from "./ListUserAccount";
import { Button, Modal } from "react-bootstrap";
import FormUsers from "./Owner/FormUser";
import { getAllUsersService } from "../services/UsersServices";
import AccountD from "../assets/p-info.png";
import Admin from "../assets/admin.png";
import AccountA from "../assets/p-info-active.png";
import AdminA from "../assets/admin-active.png";

function AccountTable({ column1, column2, column3, column4 }) {
   const [userAll, setUserAll] = useState(undefined);
   const [title, setTitle] = useState("Personal Information");
   const userRole = useSelector((state) => state.role.role);
   const location = useLocation();
   const [show, setShow] = useState(false);
   const [imageSrc, setImageSrc] = useState(AccountD);
   const [adminImageSrc, setAdminImageSrc] = useState(Admin);
   const [menuFontWeight, setMenuFontWeight] = useState("fw-normal");
   const [menuFontWeightAdm, setMenuFontWeightAdm] = useState("fw-normal");
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [order, setOrder] = useState({ col: null, type: null });

   const clickedElement = (title) => {
      setTitle(title);
   };

   useEffect(() => {
      if (location.pathname === "/account") {
         setTitle("Personal Information");
         setImageSrc(AccountA);
         setAdminImageSrc(Admin);
         setMenuFontWeight("fw-bold");
         setMenuFontWeightAdm("fw-normal");
      } else if (location.pathname === "/account/users") {
         setTitle("Administration");
         setImageSrc(AccountD);
         setAdminImageSrc(AdminA);
         setMenuFontWeight("fw-normal");
         setMenuFontWeightAdm("fw-bold");
      }
   }, [location.pathname]);

   useEffect(() => {
      getAllUsers();
   }, []);

   const getAllUsers = async () => {
      setUserAll(await getAllUsersService());
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof userAll[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...userAll].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setUserAll(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   /* const associateUserToFolder = async (e, user_id) => {
      console.log(`${e.value} : ${user_id}`);

      try {
         const result = await associateUserToFolderService(user_id, e.value);
         getAllUsers();
         console.log(result);
      } catch (error) {
         console.log(error);
      }
   }; */

   return (
      <>
         <div className="tableWrapper tableWrapper--account mx-auto">
            <div className="mt-4">
               <div className=" mx-auto mt-4">
                  <div className="primarybar fw-normal fs-3 p-2 align-items-center overflow-hidden">
                     <h3 className="mx-auto pt-2">My Account</h3>
                  </div>
                  <div className="dataTable dataTable--account mx-auto">
                     <div className="theadwrapper p-0">
                        <div className="secondbar--account py-2 fw-bold d-flex px-4 fs-6">
                           <h4 className="budgetName m-auto m-md-0 p-1 w-100">
                              {title}
                           </h4>
                           {location.pathname === "/account/users" && (
                              <button
                                 type="button"
                                 className="addBtn addBtn--account btn btn-lg  ms-5 m-auto d-flex align-items-center justify-content-center"
                                 onClick={handleShow}
                              >
                                 +
                              </button>
                           )}
                        </div>
                     </div>
                     <div className="row g-0">
                        <div className="menu--account col-2 text-dark text-center">
                           <NavLink
                              exact
                              //   activeClassName={"btn btn-info text-white"}
                              to={"/account"}
                              className={`col d-flex flex-column menu-nav--account align-items-center py-3 fw-normal ${menuFontWeight}`}
                              onClick={() =>
                                 clickedElement("Personal Information")
                              }
                           >
                              <img
                                 src={imageSrc}
                                 className="icon--account"
                                 alt="icon--account"
                              />
                              <span className="menu-titles">
                                 Personal Information
                              </span>
                           </NavLink>

                           {userRole === "Owner" && (
                              <NavLink
                                 //  activeClassName={"btn btn-info text-white"}
                                 to={"/account/users"}
                                 className={`col d-flex flex-column menu-nav--account align-items-center py-3 fw-normal ${menuFontWeightAdm}`}
                                 // className="col btn btn-primary  w-75 mb-3 rounded-pill"
                                 onClick={() =>
                                    clickedElement("Administration")
                                 }
                              >
                                 <img
                                    src={adminImageSrc}
                                    className="icon--account"
                                    alt="icon--account"
                                 />
                                 <span className="menu-titles">
                                    Administration
                                 </span>
                              </NavLink>
                           )}
                        </div>
                        <div className="col-10">
                           <Switch>
                              <Route exact path="/account">
                                 <div className="list-container list-container--contract overflow-auto">
                                    <div className="container-sm">
                                       <InformationPersonal />
                                    </div>
                                 </div>
                              </Route>

                              {userRole === "Owner" && (
                                 <Route exact path="/account/users">
                                    <ListUserAccount
                                       column1="Name"
                                       column2="Email"
                                       column3="Role"
                                       column4="Folder"
                                       userAll={userAll && userAll}
                                       getAllUsers={getAllUsers}
                                       sorting={sorting}
                                       getSortingIndicator={getSortingIndicator}
                                    />
                                 </Route>
                              )}
                           </Switch>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton className="modal-header">
               <h5 className="modal-title">Add new user form</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
               <FormUsers getAllUsers={getAllUsers} handleClose={handleClose} />
            </Modal.Body>
            <Modal.Footer className="modal-footer">
               <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
               >
                  Close
               </button>
               <Button
                  type="submit"
                  form="user-form"
                  className="btn btn-primary"
               >
                  Add
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
}

export { AccountTable };
