import { Layout } from "../components/Layout";
import { LoginContainer } from "../components/Login/LoginContainer";

function Login() {
   document.title = "Login – Envision Education";

   return (
      <>
         <Layout
            goBackBtn={true}
            budgetBar={true}
            currentUser={true}
            accountBtn={true}
         >
            <LoginContainer />
         </Layout>
      </>
   );
}

export { Login };
