import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

export default function FormUsers({handleClose,getAllUsers}) {
  
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const password = watch('password');
  const [errorMsg, setErrorMsg] = useState("");
  const onSubmit = async (data) => {
    setErrorMsg("")
    try {
      await axios.post("/signup", {
        user: data,
      });
      reset({ email: "", name: "" , password: "", password_confirmation: "", role: ""});
      handleClose()
      getAllUsers()
    } catch (error) {
      setErrorMsg(error.response.data.status.message ? error.response.data.status.message : error.message )
      console.log(error);
    }
  };
  return (
    <>
      {/* <button type="button" className="btn btn-primary" onClick={handleShow}>
        New user
      </button> */}
      


          <form onSubmit={handleSubmit(onSubmit)} className="text-black text-start" id="user-form">
            <div className="mb-3">
              <label htmlFor="formEmail" className="form-label">Email address</label>
              <input type="email" className="form-control" id="formEmail" {...register("email", { required: true })} />
              {errors.email && <span className="text-danger">This field is required</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="formName" className="form-label">Full Name</label>
              <input type="text" className="form-control" id="formName" {...register("name", { required: true })} />
              {errors.name && <span className="text-danger">This field is required</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="formPassword" className="form-label">Password</label>
              <input type="password" className="form-control" id="formPassword" {...register("password", { required: true })} />
              {errors.password && <span className="text-danger">This field is required</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="formConfirmPassword" className="form-label">Confirm password</label>
              <input type="password" className="form-control" id="formConfirmPassword" {...register("password_confirmation", { required: true, validate: (value) => value === password || "Passwords do not match" })} />
              {errors.password_confirmation && <span className="text-danger">{errors.password_confirmation.message}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="role" className="form-label">Role</label>
              <select className="form-select" id="role" {...register("role", { required: true })}>
                <option className="text-start" value=""></option>
                <option className="text-start" value="Admin">Admin</option>
                <option className="text-start" value="User">User</option>
              </select>
              {errors.role && <span className="text-danger">This field is required</span>}
            </div>
          </form>
          <span>{errorMsg}</span>


       


    </>
  );
}
