import { useEffect, useState } from "react";
import "../styles/Table.css";
import "../styles/ContractTable.css";
import "../styles/EncumbranceTable.css";
import { Link, useParams } from "react-router-dom";
import {
   deleteEncumbrancesService,
   getAllEncumbrancesByBudgetService,
   getTotalEncumbranceService,
   getTotalOutstandingService,
   updateEmcumbrancerService,
} from "../services/EncumbrancesServices";
import { getBudgetByIdService } from "../services/BudgetService";
import FormEncumbrances from "./FormEncumbrances";
import { ViewDateYear, ViewDollar } from "../utils";
import { OutstandingBalance } from "./OutstandingBalance";
import { DynamicText } from "./DynamicText";
import { Button, Modal } from "react-bootstrap";
import Edit from "../assets/edit.png";
import Save from "../assets/save.png";
import Delete from "../assets/delete.png";
import Stats from "../assets/stats-bright.png";
import { getGrantByIdService } from "../services/GrantService";
import { getFolderByIdService } from "../services/FolderService";
import { useDispatch, useSelector } from "react-redux";
import { setGrantCurrent } from "../redux/Reducers/roleSlice";
import { setContractCurrent } from "../redux/Reducers/roleSlice";
import { toast } from "react-hot-toast";

function AdminEncumbranceTable({
   column1,
   column2,
   column3,
   column4,
   column5,
   column6,
   column7,
   column8,
   parentCallback,
}) {
   const contractCurrent = useSelector((state) => state.role.contractCurrent);
   const [content, setContent] = useState(undefined);
   const [budgetCurrent, setBudgetCurrent] = useState();
   const [show, setShow] = useState(false);
   const [totalEncumbraces, settotalEncumbraces] = useState(undefined);
   const [totalOutstanding, setTotalOutstanding] = useState(undefined);
   /*    const [grantCurrent, setGrantCurrent] = useState(undefined); */
   const grantCurrent = useSelector((state) => state.role.grantCurrent);
   const [currentFolder, setCurrentFolder] = useState(undefined);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [isEditing, setIsEditing] = useState(null);
   const [order, setOrder] = useState({ col: null, type: null });
   /* const [folderAll, setFolderAll] = useState(); */
   const [showDelete, setShowDelete] = useState(false);
   const [idDelete, setIdDelete] = useState(undefined);
   const { folder_id, id } = useParams();

   const dispatch = useDispatch();

   useEffect(() => {
      getAllEncumbrancesByBudget(id);
      //getAllFolder();
   }, [id]);

   useEffect(() => {
      if (folder_id) {
         // getAllProjectsByBudget(folder_id);
         getFolderById(folder_id);
      }
   }, [folder_id]);

   useEffect(() => {
      if (grantCurrent) {
         parentCallback(grantCurrent.total_remaining_percentage);
      }
   }, [parentCallback, grantCurrent]);

   useEffect(() => {
      if (budgetCurrent) {
         dispatch(setContractCurrent(budgetCurrent.remaining_percentage));
      }
   }, [content]);

   const getAllEncumbrancesByBudget = async (id) => {
      setBudgetCurrent(await getBudgetByIdService(id));
      setContent(await getAllEncumbrancesByBudgetService(id));
      settotalEncumbraces(await getTotalEncumbranceService(id));
      setTotalOutstanding(await getTotalOutstandingService(id));
   };

   const getFolderById = async (folder_id) => {
      setCurrentFolder(await getFolderByIdService(folder_id));
      getFolderByIdService(folder_id);
   };

   /* const getAllFolder = async () => {
    setFolderAll(await getAllFolderService());
  }; */

   if (budgetCurrent) {
      dispatch(setContractCurrent(budgetCurrent.remaining_percentage));
   }

   const handleEdit = (eve, index) => {
      eve.preventDefault();
      setIsEditing(index);
   };

   const handleSave = async (eve, index) => {
      eve.preventDefault();
      try {
         await updateEmcumbrancerService(content[index]);
         getAllEncumbrancesByBudget(id);
         try {
            dispatch(
               setGrantCurrent(await getGrantByIdService(grantCurrent.id))
            );
         } catch (error) {
            console.error(error);
         }
         setIsEditing(null);
         toast.success("Updated Expense");
      } catch (error) {
         console.log(error);
         if (error.response.status === 422) {
            error.response.data.error.map((c) => toast.error(c));
         }
      }
   };

   const handleDelete = (eve, index) => {
      eve.preventDefault();
      setShowDelete(true);
      setIdDelete(content[index].id);
   };

   const handleInputChange = (eve, index) => {
      const { name, value } = eve.target;
      setContent(
         content.map((data, i) =>
            i === index
               ? {
                    ...data,
                    [name]: value,
                 }
               : data
         )
      );
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof content[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...content].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setContent(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   /* const folderName = (data, n) => {
    const object = data.find((item) => item.id === n);
    const name = object.name;
    return name;
  }; */

   return (
      <>
         <div className="tableWrapper mx-auto">
            <div className="mt-4">
               <div className="primarybar primarybar--encumbrance fs-3 px-4 pe-md-4 ps-md-5 fw-semibold p-0 align-items-center d-flex justify-content-between">
                  {currentFolder && budgetCurrent && (
                     <DynamicText
                        text={`Expenses / ${budgetCurrent.object} - ${budgetCurrent.function} / ${budgetCurrent.description}`}
                     />
                  )}
                  {budgetCurrent && (
                     <div className="contractamount fw-normal d-flex me-4 m-md-5">
                        <button
                           type="button"
                           className="addBtn btn btn-lg ms-4 m-auto"
                           onClick={handleShow}
                        >
                           +
                        </button>
                     </div>
                  )}
               </div>
               <div className="dataTable dataTable-container mx-auto position-relative">
                  <div className="theadwrapper p-0">
                     <div className="secondbar secondbar--encumbrance ps-md-5 text-center py-2 text-white fw-bold align-items-center px-auto d-flex">
                        <p className="p-1 m-0 mt-1 fs-5 contract--n width">
                           {budgetCurrent &&
                              ViewDollar(budgetCurrent.contract_amount)}
                        </p>

                        <div className="contractamount contractamount--encumbrance fw-normal d-flex">
                           <div
                              className="available contract--n fs-5 p-md-4"
                              id="budgetName"
                           >
                              {budgetCurrent &&
                                 `${ViewDollar(budgetCurrent.remaining)}`}
                           </div>

                           <Link
                              type="button"
                              className="m-auto px-3"
                              to={`/folder/${folder_id}/projects/encumbrance/graphic/${id}`}
                           >
                              <img
                                 alt="Stats"
                                 style={{ height: "1.9em", width: "1.9em" }}
                                 src={Stats}
                              ></img>
                           </Link>
                        </div>
                     </div>
                  </div>

                  <div className="list-container list-container--contract overflow-auto position-relative">
                     <div className="rowItem row-container row-container--encumbrance px-4 fw-bold">
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_po_name")}
                        >
                           {column1} {getSortingIndicator("contract_po_name")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("id_new")}
                        >
                           {column2} {getSortingIndicator("id_new")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("invoice_date")}
                        >
                           {column3} {getSortingIndicator("invoice_date")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("encumbrance")}
                        >
                           {column4} {getSortingIndicator("encumbrance")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("invoice_due")}
                        >
                           {column5} {getSortingIndicator("invoice_due")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("payment_date")}
                        >
                           {column6} {getSortingIndicator("payment_date")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("payment_amount")}
                        >
                           {column7} {getSortingIndicator("payment_amount")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("outstanding_balance")}
                        >
                           {column8}{" "}
                           {getSortingIndicator("outstanding_balance")}
                        </div>
                        <div className="attribute attribute-update my-auto ps-2">
                           Update
                        </div>
                     </div>
                     {content && content.length === 0 && (
                        <div className="container mt-5">
                           <p
                              className="text-center fs-4 fw-semibold"
                              style={{ color: "var(--secondary-color)" }}
                           >
                              No expense data has been created yet
                           </p>
                        </div>
                     )}
                     {content &&
                        content.map((data, index) => (
                           <form
                              key={index}
                              className="rowItem row-container row-container--encumbrance px-4 align-items-center"
                           >
                              <div className="attribute" data-name={column1}>
                                 {data.contract_po_name}
                              </div>

                              {/* <div className="attribute" data-name={column2}>
                                 {data.id_new}
                              </div> */}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column2}>
                                    <input
                                       className="input"
                                       type="number"
                                       value={data.id_new}
                                       name="id_new"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column2}>
                                    {data.id_new && data.id_new}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column3}>
                                    <input
                                       className="input"
                                       type="date"
                                       value={data.invoice_date}
                                       name="invoice_date"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column3}>
                                    {data.invoice_date &&
                                       ViewDateYear(data.invoice_date)}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div
                                    data-name={column4}
                                    className="attribute position-relative p-0"
                                 >
                                    <span className="input-dollar--sign">
                                       $
                                    </span>
                                    <input
                                       className="input input-dollar"
                                       type="number"
                                       value={data.encumbrance}
                                       name="encumbrance"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column4}>
                                    {ViewDollar(data.encumbrance)}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column5}>
                                    <input
                                       className="input"
                                       type="date"
                                       value={data.invoice_due}
                                       name="invoice_due"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column5}>
                                    {data.invoice_due &&
                                       ViewDateYear(data.invoice_due)}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column6}>
                                    <input
                                       className="input"
                                       type="date"
                                       value={data.payment_date}
                                       name="payment_date"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column6}>
                                    {data.payment_date &&
                                       ViewDateYear(data.payment_date)}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div
                                    data-name={column7}
                                    className="attribute position-relative"
                                 >
                                    <span className="input-dollar--sign">
                                       $
                                    </span>
                                    <input
                                       className="input input-dollar"
                                       type="number"
                                       value={data.payment_amount}
                                       name="payment_amount"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div className="attribute" data-name={column7}>
                                    {ViewDollar(data.payment_amount)}
                                 </div>
                              )}

                              <div
                                 data-name={column8}
                                 className="attribute position-relative h-100 p-0"
                              >
                                 <OutstandingBalance
                                    value={data.outstanding_balance}
                                 />
                              </div>
                              <div
                                 className="attribute d-md-flex ps-md-2"
                                 data-name="Update"
                              >
                                 <span className="d-flex">
                                    {isEditing === index ? (
                                       <button
                                          className="btn update-button--save d-flex p-1"
                                          onClick={(eve) =>
                                             handleSave(eve, index)
                                          }
                                       >
                                          <img
                                             className="icon-update"
                                             alt="Save"
                                             src={Save}
                                          ></img>
                                       </button>
                                    ) : (
                                       <button
                                          className="btn update-button d-flex p-1 align-items-center"
                                          onClick={(eve) =>
                                             handleEdit(eve, index)
                                          }
                                       >
                                          <img
                                             className="icon-update"
                                             alt="Edit"
                                             src={Edit}
                                          ></img>
                                       </button>
                                    )}
                                    <button
                                       className="btn update-button d-flex p-1 ms-1 align-items-center"
                                       onClick={(eve) =>
                                          handleDelete(eve, index)
                                       }
                                    >
                                       <img
                                          className="icon-update"
                                          alt="Delete"
                                          src={Delete}
                                       ></img>
                                    </button>
                                 </span>
                              </div>
                           </form>
                        ))}
                     <div
                        className="separator"
                        style={{ background: "none" }}
                     ></div>
                  </div>
                  <div className="row-container lastRowItem lastRowItem--encumbrances px-4 fw-bold align-items-center">
                     <div className="attribute percent"></div>
                     <div className="attribute percent"></div>
                     <div className="attribute percent text-end">
                        Total:&nbsp; &nbsp;
                     </div>

                     <div className="attribute d-flex align-self-center m-auto m-md-0">
                        {totalEncumbraces && ViewDollar(totalEncumbraces)}
                     </div>
                     <div className="attribute percent"></div>
                     <div className="attribute percent"></div>
                     <div className="attribute percent">
                        {totalOutstanding &&
                           totalEncumbraces &&
                           ViewDollar(totalEncumbraces - totalOutstanding)}
                     </div>
                     <div className="attribute d-flex align-self-center m-auto m-md-0">
                        {totalOutstanding && ViewDollar(totalOutstanding)}
                     </div>
                     <div className="attribute percent"></div>
                  </div>
               </div>
            </div>

            {/* Modal Delete Start */}
            <Modal
               centered
               size="lg"
               show={showDelete}
               onHide={() => setShowDelete(false)}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Warning </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <p>Are you sure you want to delete this invoice</p>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowDelete(false)}
                  >
                     Close
                  </Button>
                  <Button
                     variant="danger"
                     onClick={async () => {
                        try {
                           const result = await deleteEncumbrancesService(
                              idDelete
                           );
                           console.log(result);
                           setShowDelete(false);
                           getAllEncumbrancesByBudget(id);
                        } catch (error) {
                           console.log(error);
                        }
                     }}
                  >
                     Delete
                  </Button>
               </Modal.Footer>
            </Modal>
            {/* Modal Delete End */}

            <FormEncumbrances
               show={show}
               handleCloseModal={handleClose}
               id={budgetCurrent && budgetCurrent.id}
               getAllEncumbrancesByBudget={getAllEncumbrancesByBudget}
            />
         </div>
      </>
   );
}

export { AdminEncumbranceTable };
