import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { createBudgetService } from "../services/BudgetService";
import CurrencyInput from "react-currency-input-field";
import { intlConfig } from "../utils";
import { setGrantCurrent } from "../redux/Reducers/roleSlice";
import { getGrantByIdService } from "../services/GrantService";
import { useDispatch, useSelector } from "react-redux";

export default function FormProject({
  handleClose,
  grant_id,
  getAllBudgetByGrant,
  total_remaining,
}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const grantCurrent = useSelector((state) => state.role.grantCurrent);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (
      await createBudgetService({
        budget: Object.assign(data, { grant_id: grant_id }),
      })
    ) {
      handleClose();
      getAllBudgetByGrant(grant_id);
      reset();
      dispatch(setGrantCurrent(await getGrantByIdService(grantCurrent.id)));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label className=" form-label" htmlFor="object">
            Object
          </label>
          <input
            type="text"
            className="form-control "
            name="object"
            id="object"
            {...register("object", {
              required: "object is required",
            })}
          />

          {errors.object?.type === "required" && (
            <small id="name" className="ms-2 form-text fw-semibold text-danger">
              {errors.object?.message}
            </small>
          )}
        </div>

        <div className="mb-3">
          <label className=" form-label" htmlFor="function">
            Contract
          </label>
          <input
            type="text"
            className="form-control "
            name="function"
            id="function"
            {...register("function", {
              required: "function is required",
            })}
          />

          {errors.function?.type === "required" && (
            <small id="name" className="ms-2 form-text fw-semibold text-danger">
              {errors.function?.message}
            </small>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
          <input
            type="text"
            className="form-control "
            name="description"
            id="description"
            {...register("description", {
              required: "Description is required",
              pattern: {
                value: /^[A-Za-z0-9\s]+$/g,
                message: "Valid only letters and numbers",
              },
            })}
          />

          {errors.description?.type === "required" && (
            <small id="name" className="ms-2 form-text fw-semibold text-danger">
              {errors.description?.message}
            </small>
          )}
          {errors.description?.type === "pattern" && (
            <small
              id="total_amount"
              className="`ms-2 form-text fw-semibold text-danger"
            >
              {errors.description?.message}
            </small>
          )}
        </div>

        <div className=" mb-3">
          <label className=" form-label" htmlFor="department_program">
            Department or Program
          </label>
          <input
            type="text"
            className="form-control"
            name="department_program"
            id="department_program"
            {...register("department_program", {
              required: "Department or program is required",
              pattern: {
                value: /^[A-Za-z0-9\s]+$/g,
                message: "Valid only letters and numbers",
              },
            })}
          />

          {errors.department_program?.type === "required" && (
            <small id="name" className="ms-2 form-text fw-semibold text-danger">
              {errors.department_program?.message}
            </small>
          )}
          {errors.department_program?.type === "pattern" && (
            <small
              id="total_amount"
              className="`ms-2 form-text fw-semibold text-danger"
            >
              {errors.department_program?.message}
            </small>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="contract_amount">
            Contract Amount
          </label>

          <Controller
            name="contract_amount"
            control={control}
            id="contract_amount"
            rules={{
              required: "Contract amount is required",
              pattern: {
                value: /^(?:\d+|\d+(?:\.\d{1,2})?)$/,
                message: "The contract amount must be a valid number",
              },
              min: {
                value: 0.01,
                message: "The contract amount must be greater than zero",
              },
              max: {
                value: total_remaining,
                message: "The Grant does not have funds for this amount",
              },
            }}
            render={({ field }) => (
              <CurrencyInput
                id="contract_amount"
                className="form-control"
                name="contract_amount"
                placeholder=""
                intlConfig={intlConfig}
                decimalsLimit={2}
                onValueChange={(value, name) => {
                  field.onChange(value);
                }}
              />
            )}
          />

          {/* <input
            type="number"
            step="0.01"
            className="form-control"
            name="contract_amount"
            id="contract_amount"
            {...register("contract_amount", {
              required: "Contract amount is required",
              pattern: {
                value: /^(?:\d+|\d+(?:\.\d{1,2})?)$/,
                message: "The contract amount must be a valid number",
              },
              min: {
                value: 0.01,
                message: "The contract amount must be greater than zero",
              },
              max: {
                value: total_remaining,
                message: "The folder does not have funds for this amount",
              },
            })}
          /> */}

          <ErrorMessage
            errors={errors}
            name="contract_amount"
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <small
                  key={type}
                  id="name"
                  className="ms-2 form-text fw-semibold text-danger"
                >
                  {message}
                </small>
              ))
            }
          />
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
}
