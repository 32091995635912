import "../styles/AccountButton.css";
import account from "../assets/account.png";
import { Link } from "react-router-dom";

function AccountButton() {
   return (
      <>
         <div className="d-none d-md-block">
            <Link
               to={"/account"}
               className="btn_account position-absolute d-flex fw-semibold fs-3 flex-row-reverse"
            >
               <div className="btn_account-circle">
                  <img className="goback__image" src={account} alt="goback__image"></img>
               </div>
               Account
            </Link>
         </div>
      </>
   );
}

export { AccountButton };
