import { Layout } from "../components/Layout";
import { AccountTable } from "../components/AccountTable";
import Report from "../components/Report";

function ReportByGrant() {
   document.title = "Report by Grant – Envision Education";

   return (
      <>
         <Layout budgetBar={true}>
            <Report />
         </Layout>
      </>
   );
}

export { ReportByGrant };
