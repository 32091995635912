import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAllContractByBudgetService } from "../services/ContractServices";
import { Controller, useForm } from "react-hook-form";
import { createEncumbrancesServices } from "../services/EncumbrancesServices";
import CurrencyInput from "react-currency-input-field";
import { intlConfig } from "../utils";

export default function FormEncumbrances({
   show,
   handleCloseModal,
   id,
   getAllEncumbrancesByBudget,
}) {
   const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
   } = useForm({
      criteriaMode: "all",
   });

   const [errorResponse, setErrorResponse] = useState(undefined);

   const [contractsAll, setContractsAll] = useState([]);
   const [contract_po_nameState, setContract_po_nameState] = useState("");
   useEffect(() => {
      if (id) {
         getAllContracts(id);
      }
   }, [id]);

   const getAllContracts = async (id) => {
      setContractsAll(await getAllContractByBudgetService(id));
   };

   const onSubmit = async (data) => {
      setErrorResponse(undefined);
      try {
         await createEncumbrancesServices({
            encumbrance: Object.assign(data, {
               invoice_date: new Date().toISOString(),
            }),
         });

         handleCloseModal();
         getAllEncumbrancesByBudget(id);
         reset();
         setContract_po_nameState("");
      } catch (error) {
         if (error.response.status === 422) {
            setErrorResponse(error.response.data.error);
         }
      }
   };

   return (
      <>
         <Modal centered show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <Modal.Title>Create New Expense</Modal.Title>
            </Modal.Header>

            <Form onSubmit={handleSubmit(onSubmit)} className="text-dark">
               <Modal.Body>
                  <div className="mb-2">
                     <Form.Label> Company / Name </Form.Label>
                     <Form.Select
                        name="contract_id"
                        {...register("contract_id", {
                           validate: (value) =>
                              value !== "" || "Contract is required",
                        })}
                        onChange={(e) => {
                           const selectedContractId = parseInt(e.target.value);
                           const selectedContract = contractsAll.find(
                              (c) => c.id === selectedContractId
                           );
                           if (selectedContract) {
                              setContract_po_nameState(
                                 `${selectedContract.contract_po} - ${selectedContract.company_name}`
                              );
                           } else {
                              setContract_po_nameState("");
                           }
                        }}
                     >
                        <option className="text-start" value="">
                           Select a contract
                        </option>
                        {contractsAll &&
                           contractsAll.map((c) => (
                              <option
                                 className="text-start"
                                 value={c.id}
                                 key={c.id}
                              >
                                 {c.company_name}
                              </option>
                           ))}
                     </Form.Select>
                     {errors.contract_id && (
                        <p className="text-danger">
                           {errors.contract_id.message}
                        </p>
                     )}
                  </div>

                  <div className="mb-2">
                     <Form.Label> Contract / PO — Name </Form.Label>
                     <Form.Control
                        readOnly
                        name="contract_po_name"
                        type="text"
                        placeholder=""
                        value={contract_po_nameState}
                        {...register("contract_po_name")}
                     />
                  </div>

                  <div className="mb-2">
                     <Form.Label> Expense </Form.Label>
                     <Controller
                        name="encumbrance"
                        control={control}
                        rules={{
                           required: "Expense is required",
                        }}
                        render={({ field }) => (
                           <CurrencyInput
                              id="encumbrance"
                              className="form-control"
                              name="encumbrance"
                              intlConfig={intlConfig}
                              decimalsLimit={2}
                              onValueChange={(value, name) => {
                                 field.onChange(value);
                              }}
                           />
                        )}
                     />
                     {/* <Form.Control
                name="encumbrance"
                type="text"
                placeholder=""
                {...register("encumbrance", {
                  required: "Encumbrance is required",
                })}
              /> */}
                     {errors.encumbrance && (
                        <p className="text-danger">
                           {errors.encumbrance.message}
                        </p>
                     )}
                  </div>

                  <div className="mb-2">
                     <Form.Label> Invoice Number </Form.Label>
                     <Form.Control
                        name="id_new"
                        type="number"
                        placeholder=""
                        {...register("id_new", {
                           required: "Invoice number is required",
                        })}
                     />
                     {errors.id_new && (
                        <p className="text-danger">
                           {errors.invoice_due.message}
                        </p>
                     )}
                  </div>

                  <div className="mb-2">
                     <Form.Label> Invoice Due </Form.Label>
                     <Form.Control
                        name="invoice_due"
                        type="date"
                        placeholder=""
                        {...register("invoice_due", {
                           required: "Invoice due is required",
                        })}
                     />
                     {errors.invoice_due && (
                        <p className="text-danger">
                           {errors.invoice_due.message}
                        </p>
                     )}
                  </div>

                  {errorResponse &&
                     errorResponse.map((e) => (
                        <Form.Text className="ms-3 fs-6 fw-semibold text-danger d-block">
                           {e}
                        </Form.Text>
                     ))}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                     Close
                  </Button>
                  <Button variant="success" type="submit">
                     Save
                  </Button>
               </Modal.Footer>
            </Form>
         </Modal>
      </>
   );
}
