import axios from "axios";

export const getAllBudgetByFolderService = async (id) => {
  try {
    const result = await axios.get(`/grant/${id}/budgets`);
    const parsedData = result.data.map((budget) => ({
      ...budget,
      contract_amount: parseFloat(budget.contract_amount).toFixed(2),
      expenses: parseFloat(budget.expenses).toFixed(2),
      remaining: parseFloat(budget.remaining).toFixed(2),
    }));
    return parsedData;
  } catch (error) {
    console.log(error);
    return[]
  }
};

export const createBudgetService = async (data) => {
  try {
    const result = await axios.post(`/budgets`, data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateBudgetService = async (id, editedData) => {
  try {
    const result = await axios.patch(`/budgets/${id}`, editedData);
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBudgetByIdService = async (id) => {
  try {
    const result = await axios.get(`/budget/${id}`);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBudgetService = async (id) => {
  try {
    const result = await axios.delete(`/budgets/${id}`);
    return result;
  } catch (error) {
   console.log(error);
  }
};
