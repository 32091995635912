import axios from "axios";

export const getAllGrantByFolderService = async (id) => {
   try {
      const result = await axios.get(`/folders/${id}/grants`);
      const parsedData = result.data.map((grant) => ({
         ...grant,
         contract_amount: parseFloat(grant.contract_amount).toFixed(2),
         expenses: parseFloat(grant.expenses).toFixed(2),
         remaining: parseFloat(grant.remaining).toFixed(2),
      }));
      return parsedData;
   } catch (error) {
      return [];
   }
};

export const createGrantService = async (grant) => {
   try {
      await axios.post("/grants", grant);
      return true;
   } catch (error) {
      console.log(error);
      return false;
   }
};

export const getGrantByIdService = async (id) => {
   try {
      const result = await axios.get(`/grants/${id}`);
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const editGrantService = async (data) => {
   return axios.patch(`/grants/${data.id}/`, data)
}

export const deleteGrantByIdService = async (id) => {
   return await axios.delete(`/grants/${id}`);
};

export const getGrantReportService = async (grant) => {
   try {
      const result = await axios.get(`/report/${grant}`);
      return result.data;
   } catch (error) {
      console.log(error);
   }
};
