import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   role: "",
   folderId: null,
   grantCurrent: undefined,
   contractCurrent: undefined,
};

const roleSlice = createSlice({
   name: "role",
   initialState,
   reducers: {
      setRole: (state, action) => {
         state.role = action.payload;
      },
      setFolderId: (state, action) => {
         state.folderId = action.payload;
      },
      setGrantCurrent: (state, action) => {
         state.grantCurrent = action.payload;
      },
      setContractCurrent: (state, action) => {
         state.contractCurrent = action.payload;
      },
   },
});

console.log(roleSlice);

export const { setRole, setFolderId, setGrantCurrent, setContractCurrent } =
   roleSlice.actions;
export default roleSlice.reducer;
