import { useEffect, useState } from "react";
import "../styles/Table.css";
import "../styles/ProjectsTable.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import FormProject from "./FormProject";
import BadgeRemaining from "./BadgeRemaining";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllBudgetByFolderService } from "../services/BudgetService";
import Info from "../assets/Info.png";
import { ViewDollar } from "../utils";
import {
   getAllGrantByFolderService,
   getGrantByIdService,
} from "../services/GrantService";
import Select from "react-select";
import { setGrantCurrent } from "../redux/Reducers/roleSlice";
import { useDispatch, useSelector } from "react-redux";

function UserProjectsTable({
   column1,
   column2,
   column3,
   column4,
   column5,
   column6,
   column7,
   column8,
   parentCallback,
}) {
   const grantCurrent = useSelector((state) => state.role.grantCurrent);
   const [options, setOptions] = useState(undefined);
   const [selectedOption, setSelectedOption] = useState(null);

   const dispatch = useDispatch();

   const [modalState, setModalState] = useState(false);
   /* const handleShowModalOne = () => setModalState("modalOne"); */
   const handleShowModalTwo = () => setModalState("modalTwo");
   const handleClose = () => setModalState(false);

   const [content, setContent] = useState(undefined);
   /* const [grantsAll, setGrantsAll] = useState(undefined); */
   /* const [isEditing, setIsEditing] = useState(null); */

   const [order, setOrder] = useState({ col: null, type: null });

   const { folder_id } = useParams();
   useEffect(() => {
      if (folder_id) {
         getAllGrantsByFolder(folder_id);
         // getAllProjectsByBudget(folder_id);
         //getFolderById(folder_id);
      }
      if (grantCurrent) {
         getAllBudgetByGrant(grantCurrent.id);
      }
   }, [folder_id, grantCurrent]);

   useEffect(() => {
      if (grantCurrent) {
         parentCallback(grantCurrent.total_remaining_percentage);
      }
   }, [parentCallback, grantCurrent]);

   const getAllGrantsByFolder = async (folder_id) => {
      /* setGrantsAll(await getAllGrantByFolderService(folder_id)); */

      const result = await getAllGrantByFolderService(folder_id);
      const p = result.map((b) => {
         return { value: b.id, label: `${b.name}` };
      });
      setOptions(p);
   };

   const getAllBudgetByGrant = async (grant_id) => {
      setContent(await getAllBudgetByFolderService(grant_id));
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof content[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...content].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setContent(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   const onChangedOption = async (eve) => {
      /* setGrantCurrent(budgetList[eve.value - 1]); */
      setSelectedOption(selectedOption);
      if (eve.label === "Create Grant") {
         dispatch(setGrantCurrent(undefined));
         handleShowModalTwo();
         setContent(undefined);
      } else {
         dispatch(setGrantCurrent(await getGrantByIdService(eve.value)));
         getAllBudgetByGrant(eve.value);
      }
   };

   return (
      <>
         <div className="tableWrapper mx-auto">
            <div className="mt-4">
               <div className="primarybar primarybar--projects fs-3 p-4 px-4 pe-md-4 ps-md-5 fw-semibold p-0 d-flex lh-1">
                  {/* <div className="pb-2 pb-sm-0"> */}
                  {options && (
                     <Select
                        className="text-white"
                        styles={{
                           option: (provided) => ({
                              ...provided,
                              color: "white",
                              backgroundColor: "#003151",
                              border: "none",
                           }),
                           control: (provided) => ({
                              ...provided,
                              color: "white",
                              backgroundColor: "#003151",
                              border: "none",
                              width: "14.1rem",
                           }),
                           singleValue: (provided) => ({
                              ...provided,
                              color: "white",
                              backgroundColor: "#003151",
                              height: "2.1rem",
                           }),
                           menu: (provided) => ({
                              ...provided,
                              color: "white",
                              backgroundColor: "#003151",
                              width: "14.1rem",
                              overflow: "hidden",
                              borderRadius: "1rem",
                           }),
                           menuList: (provided) => ({
                              ...provided,
                              "::-webkit-scrollbar": {
                                 width: "4px",
                                 height: "0px",
                              },
                              "::-webkit-scrollbar-track": {
                                 background: "#003151",
                              },
                              "::-webkit-scrollbar-thumb": {
                                 background: "#888",
                              },
                              "::-webkit-scrollbar-thumb:hover": {
                                 background: "#555",
                              },
                           }),
                        }}
                        defaultValue={
                           options &&
                           grantCurrent &&
                           options.find((o) => o.value === grantCurrent.id)
                        }
                        options={options && options}
                        onChange={onChangedOption}
                     />
                  )}
                  {/* </div> */}
                  <div className="contractamount contractamount--projects fw-normal d-flex justify-content-center me-sm-4 m-md-5 ms-lg-0 position-relative">
                     {grantCurrent && (
                        <p className="p-1 m-0 mt-md-1 fs-5 text-white pt-2 fs-6 mar">
                           <div className="d-flex">Grant:</div>
                           <span className="contract--n contr p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent && ViewDollar(grantCurrent.amount)}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 m-0 mt-md-1 fs-5 text-white pt-2 d-none d-md-inline fs-6 mar">
                           <div className="d-flex">
                              <span className="d-md-none d-lg-inline">
                                 T.&nbsp;{" "}
                              </span>
                              Expenses:
                           </div>
                           <span className="contract--n expen p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_expense)}`}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 m-0 mt-1 fs-5 text-white pt-2 d-none d-xl-block fs-6 mar">
                           <div className="d-flex">T. Assigned:</div>
                           <span className="contract--n p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_assigned)}`}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 m-0 mt-1 fs-5 text-white pt-2 d-md-none d-lg-inline-block fs-6 mar">
                           <div className="d-flex">T. Remaining:</div>
                           <span className="contract--n rema p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_remaining)}`}
                           </span>
                        </p>
                     )}
                  </div>
               </div>
               <div className="dataTable dataTable-container mx-auto position-relative">
                  <div className="list-container">
                     <div className="rowItem row-container px-4 fw-bold">
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("object")}
                        >
                           {column1} {getSortingIndicator("object")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("function")}
                        >
                           {column2} {getSortingIndicator("function")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("description")}
                        >
                           {column3} {getSortingIndicator("description")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("department_program")}
                        >
                           {column4} {getSortingIndicator("department_program")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_amount")}
                        >
                           {column5} {getSortingIndicator("contract_amount")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("expenses")}
                        >
                           {column6} {getSortingIndicator("expenses")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("remaining")}
                        >
                           {column7} {getSortingIndicator("remaining")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("remaining_percentage")}
                        >
                           {column8}{" "}
                           {getSortingIndicator("remaining_percentage")}
                        </div>
                     </div>
                     {!grantCurrent && (
                        <div className="container mt-5">
                           <p
                              className="text-center fs-5 fw-semibold"
                              style={{ color: "var(--secondary-color)" }}
                           >
                              You have not selected (or created) any Grant
                              within this Folder yet
                           </p>
                        </div>
                     )}

                     {content && content.length === 0 && (
                        <div className="container mt-5">
                           <p
                              className="text-center fs-4 fw-bold"
                              style={{ color: "var(--secondary-color)" }}
                           >
                              NO BUDGETS
                           </p>
                        </div>
                     )}
                     {content &&
                        content.map((data, index) => (
                           <form
                              key={index}
                              className="rowItem row-container px-4 align-items-center"
                           >
                              <Link
                                 to={`/folder/${folder_id}/projects/contract/${
                                    data && data.id
                                 }`}
                                 className="button d-flex align-items-center px-md-3"
                              >
                                 <img
                                    src={Info}
                                    className="icon pe-2"
                                    alt="icon-folder"
                                 ></img>
                                 <div
                                    className="attribute attribute-object px-md-0"
                                    data-name={column1}
                                 >
                                    {data.object}
                                 </div>
                              </Link>

                              <div data-name={column2} className="attribute">
                                 {data.function}
                              </div>

                              <div data-name={column2} className="attribute">
                                 {data.description}
                              </div>

                              <div data-name={column3} className="attribute">
                                 {data.department_program}
                              </div>

                              <div data-name={column4} className="attribute">
                                 {ViewDollar(data.contract_amount)}
                              </div>

                              <div data-name={column5} className="attribute">
                                 {ViewDollar(data.expenses)}
                              </div>
                              <div data-name={column6} className="attribute">
                                 {ViewDollar(data.remaining)}
                              </div>
                              <div data-name={column7} className="attribute">
                                 <BadgeRemaining
                                    remaining={parseInt(
                                       data.remaining_percentage
                                    )}
                                 />
                              </div>
                           </form>
                        ))}
                     {content && content.length > 0 && (
                        <Link
                           to={`/report/${grantCurrent && grantCurrent.id}`}
                           className="report-info text-center py-2 text-decoration-underline"
                        >
                           See the whole report here
                        </Link>
                     )}
                     {/* <div
                        className="separator"
                        style={{ background: "none" }}
                     ></div> */}
                  </div>
                  {/* <div className="row-container lastRowItem px-4 text-center fw-bold align-items-center">
                     {content && (
                        <>
                           <div className="attribute m-auto">
                              Total Expense
                              <br />
                              {grantCurrent &&
                                 ViewDollar(grantCurrent.total_expense)}
                           </div>
                           <div className="attribute percent">
                              Total Expense (%)
                              <br />
                              {grantCurrent &&
                                 parseFloat(
                                    grantCurrent.total_expense_percentage
                                 ).toFixed(2)}
                              %
                           </div>
                           <div className="attribute percent"></div>
                           <div className="attribute m-auto">
                              Total Assigned Remaining
                              <br />
                              {grantCurrent &&
                                 ViewDollar(grantCurrent.total_remaining)}
                           </div>
                           <div className="attribute percent">
                              Total A. Remaining (%)
                              <br />
                              {grantCurrent &&
                                 parseFloat(
                                    grantCurrent.total_remaining_percentage
                                 ).toFixed(2)}
                              %
                           </div>
                        </>
                     )}
                  </div> */}
               </div>
            </div>

            <Modal
               centered
               show={modalState === "modalOne"}
               onHide={handleClose}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Create New Project</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {" "}
                  <FormProject
                     handleClose={handleClose}
                     grant_id={grantCurrent && grantCurrent.id}
                     getAllBudgetByGrant={getAllBudgetByGrant}
                     total_remaining={
                        grantCurrent && grantCurrent.total_remaining
                     }
                  />
               </Modal.Body>
            </Modal>
         </div>
      </>
   );
}

export { UserProjectsTable };
