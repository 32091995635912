import { Layout } from "../components/Layout";
import { AccountTable } from "../components/AccountTable";

function Account() {
   document.title = "My Account – Envision Education";

   return (
      <>
         <Layout budgetBar={true}>
            <AccountTable
               column1="email"
               column2="Full Name"
               column3="Role"
               column4="Folder"
            />
         </Layout>
      </>
   );
}

export { Account };
