import axios from "axios";

export const getAllUsersService = async () => {
  try {
    const result = await axios.get(`/users_with_folders/`);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentUserService = async (token) => {
  try {
    const result = await axios.get(`/current_user`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return result.data;
    // history.push(`/folder/${folder_id.data}/projects`);
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentUserFolder = async (id) => {
  try {
    const folder_id = await axios.get(`/userFolder/${id}`);
    return folder_id;
    // history.push(`/folder/${folder_id.data}/projects`);
  } catch (error) {
    console.log(error);
  }
};

export const associateUserToFolderService = async (user_id, folder_id) => {
  return axios.post(`/users/${user_id}/folders/${folder_id}`);
};

export const updateUserService = async (id, editedData) => {
  try {
    const result = await axios.patch(`/contracts/${id}`, editedData);
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteUserService = async (user_id) => {
  return await axios.delete(`/users/${user_id}`);
};
