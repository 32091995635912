export function ViewDollar(strt) {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return USDollar.format(strt);
}

export const intlConfig = {
  locale: "en-US",
  currency: "USD",
};

export function ViewDateYear(strt) {
  var a = strt.split("-").reverse();
  return `${a[1]}-${a[0]}-${a[2]}`
}
