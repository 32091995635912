import React from "react";
import Button from "react-bootstrap/Button";
import { Controller, useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import { intlConfig } from "../utils";
import { createGrantService, editGrantService } from "../services/GrantService";

export default function FormGrant({
  folder_id,
  getAllGrantsByFolder,
  handleClose,
  grant
}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    if (grant) {
      data.id = grant.id
      console.log(data);
      try {
        await editGrantService(data)
        getAllGrantsByFolder(folder_id);
        handleClose();
      } catch (error) {
        console.log(error);
      }

    } else {

      if (
        await createGrantService(Object.assign(data, { folder_id: folder_id }))
      ) {
        reset({ name: "", amount: "" });
        reset();
        getAllGrantsByFolder(folder_id);
        handleClose();
      }
    }

  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-dark">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name Grant
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          id="name"
          defaultValue={grant && grant.name}
          aria-describedby="name"
          placeholder=""
          {...register("name", {
            required: "Folder name is required",
            pattern: {
              value: /^(\S+\s*){1,7}$/,
              message: "Maximum of 7 words allowed",
            },
          })}
        />
        {errors.name?.type === "required" && (
          <small id="name" className="form-text fw-semibold text-danger">
            {errors.name?.message}
          </small>
        )}
        {errors.name?.type === "pattern" && (
          <small
            id="total_amount"
            className="form-text fw-semibold text-danger"
          >
            {errors.name?.message}
          </small>
        )}
      </div>

      <div className="mb-3">
        <label htmlFor="total_amount" className="form-label">
          Total Amount
        </label>
        <Controller
          name="amount"
          control={control}
          defaultValue={grant && grant.amount}
          rules={{ required: "Total amount is required" }}
          render={({ field }) => (
            <CurrencyInput
              defaultValue={grant && grant.amount}
              id="amount"
              className="form-control"
              name="amount"
              disabled={grant && grant.amount}
              placeholder=""
              intlConfig={intlConfig}
              decimalsLimit={2}
              onValueChange={(value, name) => {
                field.onChange(value);
              }}
            />
          )}
        />

        {errors.total_amount?.type === "required" && (
          <small
            id="total_amount"
            className="form-text fw-semibold text-danger"
          >
            {errors.total_amount?.message}
          </small>
        )}

        {errors.total_amount?.type === "pattern" && (
          <small
            id="total_amount"
            className="form-text fw-semibold text-danger"
          >
            {errors.total_amount?.message}
          </small>
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={handleClose} type="button" variant="danger">
          close
        </Button>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </div>
    </form>
  );
}
