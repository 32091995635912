import "../styles/Footer.css";

function Footer() {
   return (
      <footer className="text-center">
         <p className="text-center mt-3">
            © 2023 Copyright: Envision Education, LLC. All rights reserved.
         </p>
      </footer>
   );
}
export { Footer };
