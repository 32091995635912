import React, { useState } from "react";
import { Layout } from "../components/Layout";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BarCharEncumbrance } from "../components/BarCharEncumbrance";

export default function EncumbranceCharPage() {
   document.title = "Expenses Bar Graph – Envision Education";
   const [percentage, setPercentage] = useState(0);

   const handleCallback = (childData) => {
      setPercentage(childData);
   };

   const { folder_id, id } = useParams();
   return (
      <>
         <Layout percentage={percentage}>
            <BarCharEncumbrance
               id={id}
               folder_id={folder_id}
               parentCallback={handleCallback}
            />
         </Layout>
      </>
   );
}
