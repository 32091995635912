import { useState } from "react";
import { Layout } from "../components/Layout";
import { UserEncumbranceTable } from "../components/UserEncumbranceTable";

function UserEncumbrance() {
   document.title = "Expenses – Envision Education";
   const [percentage, setPercentage] = useState(0);

   const handleCallback = (childData) => {
      setPercentage(childData);
   };
   return (
      <Layout percentage={percentage}>
         <UserEncumbranceTable
            column1="Contract / PO — Name"
            column2="Invoice"
            column3="Invoice Date"
            column4="Expense"
            column5="Invoice Due"
            column6="Payment Date"
            column7="Payment"
            column8="Outstanding Balance"
            parentCallback={handleCallback}
         />
      </Layout>
   );
}

export { UserEncumbrance };
