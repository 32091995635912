import { useState } from "react";
import { Layout } from "../components/Layout";
import { AdminProjectsTable } from "../components/AdminProjectsTable";

function AdminProjects() {
   document.title = "Projects – Envision Education";
   const [percentage, setPercentage] = useState(0);

   const handleCallback = (childData) => {
      setPercentage(childData);
   };

   return (
      <Layout goBackBtn={true} percentage={percentage}>
         <AdminProjectsTable
            column1="Object"
            column2="Contract"
            column3="Description"
            column4="Department / Program"
            column5="Contract Amount"
            column6="Expenses"
            column7="Remaining"
            column8="Remaining (%)"
            parentCallback={handleCallback}
         />
      </Layout>
   );
}

export { AdminProjects };
