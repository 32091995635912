import React, { useEffect, useRef, useState } from "react";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getAllEncumbrancesByBudgetService } from "../services/EncumbrancesServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getFolderByIdService } from "../services/FolderService";
import { getGrantByIdService } from "../services/GrantService";
import { useReactToPrint } from "react-to-print";
import "../styles/BarCharEncumbrance.css";
import { ViewDateYear, ViewDollar } from "../utils";

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

export const options = {
   responsive: true,
   plugins: {
      legend: {
         display: false,
      },
      title: {
         display: true,
         text: "Expenses Chart",
         font: {
            size: 15, // Adjust the font size here
         },
      },
   },
   scales: {
      y: {
         ticks: {
            callback: (value) => `$ ${value}`,
         },
      },
   },
};

/* const labels = [
   "SP-Restroom",
   "KB-Karen Barrera",
   "KB-Karen Barrera",
   "CC-APPLE",
   "LS - LAURA JONES",
];

export const data = {
   labels,
   datasets: [
      {
         label: "Payment",
         data: labels.map(() => Math.random(0, 1000)),
         backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
   ],
}; */

export function BarCharEncumbrance({ folder_id, parentCallback }) {
   const [expensesAll, setExpensesAll] = useState(undefined);
   const [dataS, setDataS] = useState(undefined);
   const [grantCurrent, setGrantCurrent] = useState(undefined);
   const { id } = useParams();
   const componenteRef = useRef();
   const prinData = useReactToPrint({
      content: () => componenteRef.current,
      bodyClass: "chart",
      copyStyles: true,
   });

   const getAllEncumbrancesByBudget = async (id) => {
      const data = await getAllEncumbrancesByBudgetService(id);

      const backgroundColors = data.map((c) =>
         c.outstanding_balance <= 0 ? "#28a745" : "#c03743"
      );

      setExpensesAll(data);
      setDataS({
         labels: data.map((c) => `${c.contract_po_name} - ${c.id_new}`),
         datasets: [
            {
               label: "",
               data: data.map((c) => c.encumbrance),
               backgroundColor: backgroundColors,
            },
         ],
      });
   };

   useEffect(() => {
      getAllEncumbrancesByBudget(id);
   }, [id]);

   useEffect(() => {
      if (folder_id) {
         getFolderById(folder_id);
      }
   }, [folder_id]);

   useEffect(() => {
      if (grantCurrent) {
         parentCallback(grantCurrent.total_remaining_percentage);
      }
      // console.log(grantCurrent);
   }, [parentCallback, grantCurrent]);

   const getFolderById = async (folder_id) => {
      getFolderByIdService(folder_id);
      setGrantCurrent(await getGrantByIdService(folder_id));
   };

   return (
      <>
         <div ref={componenteRef} className="mt-4 text-center container">
            <div className="d-flex">
               <div className="barChartWidth mx-auto">
                  {dataS && dataS.labels.length <= 1 ? (
                     <p
                        className="mt-5 fs-5"
                        style={{ color: "var(--secondary-color)" }}
                     >
                        You will need to create more expenses in the expenses
                        table to generate the graph.
                     </p>
                  ) : (
                     dataS && <Bar options={options} data={dataS} />
                  )}
                  {console.log(dataS)}
               </div>
            </div>

            <div className="mt-3 w-100 display-it">
               {expensesAll && (
                  <table className="table text-nowrap">
                     <thead>
                        <tr>
                           <th scope="col">Contract</th>
                           <th scope="col">ID</th>
                           <th scope="col">Invoice Date</th>
                           <th scope="col">Expense</th>
                           <th scope="col">Invoice due</th>
                           <th scope="col">Payment date</th>
                           <th scope="col">Payment amount</th>
                           <th scope="col">Outstanding balance</th>
                        </tr>
                     </thead>
                     <tbody>
                        {expensesAll &&
                           expensesAll.map((e) => (
                              <tr>
                                 <td>{e.contract_po_name}</td>
                                 <th>{e.id_new}</th>
                                 <td>{ViewDateYear(e.invoice_date)}</td>
                                 <td>{ViewDollar(e.encumbrance)}</td>
                                 <td>{ViewDateYear(e.invoice_due)}</td>
                                 <td>
                                    {e.payment_date &&
                                       ViewDateYear(e.payment_date)}
                                 </td>
                                 <td>
                                    {e.payment_amount &&
                                       ViewDollar(e.payment_amount)}
                                 </td>
                                 <td>
                                    {e.outstanding_balance &&
                                       ViewDollar(e.outstanding_balance)}
                                 </td>
                              </tr>
                           ))}
                     </tbody>
                  </table>
               )}
            </div>
         </div>
         <div className="container  mt-4 text-center">
            <button className="btn btn-primary" onClick={prinData}>
               Print
            </button>
         </div>
      </>
   );
}
