import { Header } from "./Header";
import { Footer } from "./Footer";

function Layout({
   goBackBtn,
   budgetBar,
   currentUser,
   accountBtn,
   children,
   percentage,
}) {
   return (
      <>
         {/* What these props below indicate is if you don't want to show those elements, since they are displayed by default. */}
         <Header
            goBackBtn={goBackBtn}
            budgetBar={budgetBar}
            currentUser={currentUser}
            accountBtn={accountBtn}
            percentage={percentage}
         />
         {children}
         <Footer />
      </>
   );
}

export { Layout };
