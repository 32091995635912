import React from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { createContractService } from "../services/ContractServices";
import CurrencyInput from "react-currency-input-field";
import { intlConfig } from "../utils";
import { useState } from "react";

export default function FormContract({
  show,
  handleCloseModal,
  budget_id,
  getAllContractsByBudget,
}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [errorResponse, setErrorResponse] = useState(undefined);

  const onSubmit = async (data) => {
    setErrorResponse(undefined);
    try {
      await createContractService({
        contract: Object.assign(data, { budget_id }),
      });
      reset();
      handleCloseModal();
      getAllContractsByBudget(budget_id);
    } catch (error) {
      if (error.response.status === 422) {
        setErrorResponse(error.response.data.base);
      }
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>New Contracts</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Container>
              <Form.Label htmlFor="contract_po" className=" ">
                Contract / PO
              </Form.Label>

              <div className="mb-3">
                <Form.Control
                  className=""
                  id="contract_po"
                  type="text"
                  placeholder=""
                  {...register("contract_po", {
                    required: "Contract / PO is required",
                  })}
                />

                {errors.contract_po?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.contract_po?.message}
                  </Form.Text>
                )}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="company_name" className=" ">
                  Company / Name
                </Form.Label>
                <Form.Control
                  id="company_name"
                  type="text"
                  placeholder=""
                  {...register("company_name", {
                    required: "Company / Name is required",
                  })}
                />
                {errors.company_name?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.company_name?.message}
                  </Form.Text>
                )}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="department_program" className=" ">
                  Department / Program
                </Form.Label>
                <Form.Control
                  id="department_program"
                  type="text"
                  placeholder=""
                  {...register("department_program", {
                    required: "Department / Program is required",
                  })}
                />
                {errors.department_program?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.department_program?.message}
                  </Form.Text>
                )}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="contract_start_date" className=" ">
                  Contract Start Date
                </Form.Label>
                <Form.Control
                  id="contract_start_date"
                  type="date"
                  placeholder=""
                  {...register("contract_start_date", {
                    required: "Contract Date is required",
                  })}
                />
                {errors.contract_start_date?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.contract_start_date?.message}
                  </Form.Text>
                )}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="contract_end_date" className=" ">
                  Contract end Date
                </Form.Label>
                <Form.Control
                  id="contract_end_date"
                  type="date"
                  placeholder=""
                  {...register("contract_end_date", {
                    required: "Contract Date is required",
                  })}
                />
                {errors.contract_end_date?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.contract_end_date?.message}
                  </Form.Text>
                )}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="contract_amount" className=" ">
                  Contract Amount
                </Form.Label>
                <Controller
                  name="contract_amount"
                  control={control}
                  rules={{
                    required: "Contract Amount is required",
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      id="contract_amount"
                      className="form-control"
                      name="contract_amount"
                      placeholder=""
                      intlConfig={intlConfig}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />

                {/* <Form.Control
                           id="contract_amount"
                           type="text"
                           placeholder=""
                           {...register("contract_amount", {
                              required: "Contract Amount is required",
                           })}
                        /> */}
                {errors.contract_amount?.type === "required" && (
                  <Form.Text className="ms-3 text-danger d-block mb-3">
                    {errors.contract_amount?.message}
                  </Form.Text>
                )}
              </div>

              {errorResponse &&
                errorResponse.map((e) => (
                  <Form.Text className="ms-3 text-danger d-block ms-3 fs-6 fw-semibold text-danger d-block">
                    {e}
                  </Form.Text>
                ))}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>

            <Button variant="success" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
