//This makes the primarybar title text smaller so it doesn't overflow and adapts to mobile screens.

function DynamicText({ text }) {
   const words = text.split(" ");
   const isMobile = window.innerWidth < 768;
   const className =
      words.filter((word) => word !== "/").length > 4 && isMobile
         ? "fs-5"
         : words.filter((word) => word !== "/").length > 7
         ? "fs-5"
         : "";

   return <div className={className}>{text}</div>;
}

export { DynamicText };
