import { Layout } from "../components/Layout";
import ContractCharBar from "../components/ContractCharBar";
import "../styles/ContractChartBar.css";
import { useState } from "react";

export default function ContractDetailsCharBar() {
   document.title = "Contract Stats – Envision Education";
   const [percentage, setPercentage] = useState(0);

   const handleCallback = (childData) => {
      setPercentage(childData);
   };
   return (
      <>
         <Layout percentage={percentage}>
            <ContractCharBar parentCallback={handleCallback} />
         </Layout>
      </>
   );
}
