import axios from "axios";

export const getAllFolderService = async () => {
   try {
      const result = await axios.get("/folders");
      const parsedData = result.data.map((folder) => ({
         ...folder,
         total_amount: parseFloat(folder.total_amount).toFixed(2),
      }));
      return parsedData;
   } catch (error) {
      console.log(error);
      return false;
   }
};

export const createFolderService = async (folder) => {
   try {
      const result = await axios.post("/folders", folder);
      return true;
   } catch (error) {
      console.log(error);
      return false;
   }
};

export const getFolderByIdService = async (id) => {
   try {
      const result = await axios.get(`/folders/${id}`);
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const updateFolderService = async (id, editedData) => {
   try {
      const result = await axios.patch(`/folders/${id}`, editedData);
      return result;
   } catch (error) {
      console.log(error);
      throw error;
   }
};

export const deleteFolderService = async (id) => {
   try {
     const result = await axios.delete(`/folders/${id}`);
     return result;
   } catch (error) {
    console.log(error);
   }
 };
