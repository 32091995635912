import logo from "../../assets/logo.png";
import FormLogin from "./FormLogin";
import "../../styles/LoginContainer.css";

function LoginContainer() {
   return (
      <>
         <div className="login-container mx-auto mt-4">
            <div className="primarybar fw-normal fs-3 ps-md-5 p-2 pt-3 align-items-center overflow-hidden">
               <h3 className="mx-auto">Budget Tracking System</h3>
            </div>
            <div className="login-subcontainer mx-auto row align-items-center">
               <div className="col-md-4 m-auto d-flex p-0 align-items-center ps-md-3 justify-content-center">
                  <img
                     className="BTS-logo m-auto mt-5 mt-md-0 m-0"
                     src={logo}
                     alt="logo"
                  />
               </div>
               <div className="login-col col-md-8">
                  <div className="py-0 p-1 p-md-3 px-md-0 pt-md-2 me-md-5">
                     <FormLogin />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export { LoginContainer };
