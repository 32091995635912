import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import BatteryGauge from "react-battery-gauge";
import { useParams } from "react-router-dom";
import { ViewDollar } from "../utils";
// import { getFolderByIdService } from "../services/FolderService";
import { getGrantByIdService } from "../services/GrantService";
import { useReactToPrint } from "react-to-print";
import { getSummaryContractService } from "../services/ContractServices";

export default function ContractCharBar({ parentCallback }) {
  const componenteRef = useRef();
  const prinData = useReactToPrint({
    content: () => componenteRef.current,
  });

  const [contract, setContract] = useState(undefined);
  const { folder_id, id } = useParams();

  const [grantCurrent, setGrantCurrent] = useState(undefined);

  useEffect(() => {
    getSumaryContac(id);
  }, [id]);

  useEffect(() => {
    if (folder_id) {
      // getAllProjectsByBudget(folder_id);
      getFolderById(folder_id);
    }
  }, [folder_id]);

  useEffect(() => {
    if (grantCurrent) {
      console.log(grantCurrent.total_remaining_percentage);
      parentCallback(grantCurrent.total_remaining_percentage);
    }
    console.log(grantCurrent);
  }, [parentCallback, grantCurrent]);

  const getSumaryContac = async (id) => {
    try {
      const result = await getSummaryContractService(id);
      setContract(result.data);
      console.log(result);
    } catch (error) {}
  };

  // const getAllProjectsByBudget = async (id) => {
  //    setContent(await getAllBudgetByFolderService(id));
  // };

  const getFolderById = async (folder_id) => {
    // getFolderByIdService(folder_id);
    setGrantCurrent(await getGrantByIdService(folder_id));
  };

  return (
    <>
      <div ref={componenteRef} className="mt-4 container text-center">
        <h3> {contract && contract.name}</h3>
        <p>
          Contract Amount :
          {contract &&
            ViewDollar(
              parseInt(contract.encumbrances_total) +
                parseInt(contract.remaining_budget)
            )}
        </p>
        <p>
          Remaining Amount :
          {contract && ViewDollar(parseInt(contract.remaining_budget))}
        </p>
        <p>
          Expenses Amount :{" "}
          {contract && ViewDollar(parseInt(contract.encumbrances_total))}
        </p>

        {contract && (
          <BatteryGauge
            className="ms-3"
            value={contract && parseInt(contract.remaining_budget)}
            maxValue={
              contract
                ? parseInt(contract.encumbrances_total) +
                  parseInt(contract.remaining_budget)
                : 0
            }
            aspectRatio={0.48}
            animated={true}
            orientation="vertical"
            customization={{
              batteryBody: {
                strokeColor: "var(--secondary-color)",
              },
              batteryCap: {
                strokeColor: "var(--secondary-color)",
              },
              batteryMeter: {
                fill: "var(--green)",
                lowBatteryFill: "var(--red)",
                outerGap: 2.4,
                interCellsGap: 1.5,
              },
              readingText: {
                darkContrastColor: "var(--global-font)",
                lightContrastColor: "var(--secondary-color)",
                lowBatteryColor: "var(--red)",
              },
            }}
          />
        )}

        
      </div>
      <div className="container  mt-4 text-center">
      <button className="btn btn-primary" onClick={prinData}>
          Print
        </button>
      </div>
    </>
  );
}
