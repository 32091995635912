import { useEffect, useState } from "react";
import "../styles/Table.css";
import "../styles/ProjectsTable.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import FormProject from "./FormProject";
import BadgeRemaining from "./BadgeRemaining";
import {
   useHistory,
   useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
   deleteBudgetService,
   getAllBudgetByFolderService,
   updateBudgetService,
} from "../services/BudgetService";
import Info from "../assets/Info.png";
import { ViewDollar } from "../utils";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Edit from "../assets/edit.png";
import Save from "../assets/save.png";
import Delete from "../assets/delete.png";
import {
   deleteGrantByIdService,
   getAllGrantByFolderService,
   getGrantByIdService,
} from "../services/GrantService";
import FormGrant from "./FormGrant";
import { useDispatch, useSelector } from "react-redux";
import { setGrantCurrent } from "../redux/Reducers/roleSlice";
import { toast } from "react-hot-toast";

function AdminProjectsTable({
   column1,
   column2,
   column3,
   column4,
   column5,
   column6,
   column7,
   column8,
   parentCallback,
}) {
   /* const [grantCurrent, setGrantCurrent] = useState(undefined); */
   const grantCurrent = useSelector((state) => state.role.grantCurrent);
   /* const [budgetList, setBudgetList] = useState([]);
   const [isLoading, setIsLoading] = useState(true); */
   const [showDelete, setShowDelete] = useState(false);
   const [showDeleteGrant, setShowDeleteGrant] = useState(false);
   const [showEditGrant, setShowEditGrant] = useState(false);



   const [idDelete, setIdDelete] = useState(undefined);
   const [options, setOptions] = useState(undefined);
   const [selectedOption, setSelectedOption] = useState(null);

   const dispatch = useDispatch();

   const [modalState, setModalState] = useState(false);
   const handleShowModalOne = () => setModalState("modalOne");
   const handleShowModalTwo = () => setModalState("modalTwo");
   const handleClose = () => setModalState(false);

   const [content, setContent] = useState(undefined);
   /* const [grantsAll, setGrantsAll] = useState(undefined); */
   const [isEditing, setIsEditing] = useState(null);

   const [order, setOrder] = useState({ col: null, type: null });

   const { folder_id } = useParams();

   // setGrantCurrent({ ...grantCurrent, total_remaining_percentage: 0 });
   // console.log(grantCurrent);

   useEffect(() => {
      if (folder_id) {
         getAllGrantsByFolder(folder_id);
         // getAllProjectsByBudget(folder_id);
         //getFolderById(folder_id);
      }
      if (grantCurrent) {
         getAllBudgetByGrant(grantCurrent.id);
      }
   }, [folder_id, grantCurrent]);

   useEffect(() => {
      if (grantCurrent) {
         parentCallback(grantCurrent.total_remaining_percentage);
      }
   }, [parentCallback, grantCurrent]);

   const getAllGrantsByFolder = async (folder_id) => {
      /* setGrantsAll(await getAllGrantByFolderService(folder_id)); */

      const result = await getAllGrantByFolderService(folder_id);
      const p = result.map((b) => {
         return { value: b.id, label: `${b.name}` };
      });
      setOptions([{ label: "Create Grant" }, ...p]);
   };

   const getAllBudgetByGrant = async (grant_id) => {
      setContent(await getAllBudgetByFolderService(grant_id));
   };

   const handleEdit = (eve, index) => {
      eve.preventDefault();
      setIsEditing(index);
   };

   const handleSave = async (eve, index) => {
      eve.preventDefault();
      const editedData = content[index];

      try {
         await updateBudgetService(editedData.id, editedData);
         setIsEditing(null);
         getAllBudgetByGrant(grantCurrent.id);
         dispatch(setGrantCurrent(await getGrantByIdService(grantCurrent.id)));
         toast.success("Updated Budget");
      } catch (error) {
         if (error.response.status === 422) {
            error.response.data.error.map((c) => toast.error(c));
         }
      }
   };

   const handleDelete = async (eve, index) => {
      eve.preventDefault();
      setShowDelete(true);
      setIdDelete(content[index]);

      /* setContent(content.filter((_, i) => i !== index)); */
   };

   const handleInputChange = (eve, index) => {
      const { name, value } = eve.target;
      setContent((prevContent) =>
         prevContent.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
         )
      );
   };

   const sorting = (col) => {
      if (col === null || col === undefined) {
         return;
      }

      let newOrder = { col, type: null };
      if (order.col === col) {
         newOrder.type = order.type === "asc" ? "desc" : "asc";
      } else {
         newOrder.type = typeof content[0][col] === "number" ? "asc" : "desc";
      }
      setOrder(newOrder);

      const sorted = [...content].sort((a, b) => {
         const valueA = a[col] || "";
         const valueB = b[col] || "";

         if (typeof valueA === "number" && typeof valueB === "number") {
            return newOrder.type === "asc" ? valueA - valueB : valueB - valueA;
         } else {
            return newOrder.type === "asc"
               ? valueA.localeCompare(valueB, undefined, { numeric: true })
               : valueB.localeCompare(valueA, undefined, { numeric: true });
         }
      });

      setContent(sorted);
   };

   const getSortingIndicator = (col) => {
      if (order.col === col) {
         return order.type === "asc" ? "▲" : "▼";
      }
      return "";
   };

   const onChangedOption = async (eve) => {
      /* setGrantCurrent(budgetList[eve.value - 1]); */
      setSelectedOption(selectedOption);
      if (eve.label === "Create Grant") {
         dispatch(setGrantCurrent(undefined));
         handleShowModalTwo();
         setContent(undefined);
      } else {
         dispatch(setGrantCurrent(await getGrantByIdService(eve.value)));
         getAllBudgetByGrant(eve.value);
         // console.log(grantCurrent.id);
      }
   };

   return (
      <>
         <div className="tableWrapper mx-auto">
            <div className="mt-4">
               <div className="primarybar primarybar--projects fs-3 p-4 px-4 pe-md-4 ps-md-5 fw-semibold p-0 d-flex lh-1">
                  {/* <div className="pb-2 pb-sm-0"> */}
                  {options && (
                     <div className="d-flex">
                        <Select
                           className="text-white"
                           styles={{
                              option: (provided) => ({
                                 ...provided,
                                 color: "white",
                                 backgroundColor: "#003151",
                                 border: "none",
                              }),
                              control: (provided) => ({
                                 ...provided,
                                 color: "white",
                                 backgroundColor: "#003151",
                                 border: "none",
                                 width: "14.1rem",
                              }),
                              singleValue: (provided) => ({
                                 ...provided,
                                 color: "white",
                                 backgroundColor: "#003151",
                                 height: "2.1rem",
                              }),
                              menu: (provided) => ({
                                 ...provided,
                                 color: "white",
                                 backgroundColor: "#003151",
                                 width: "14.1rem",
                                 overflow: "hidden",
                                 borderRadius: "1rem",
                              }),
                              menuList: (provided) => ({
                                 ...provided,
                                 "::-webkit-scrollbar": {
                                    width: "4px",
                                    height: "0px",
                                 },
                                 "::-webkit-scrollbar-track": {
                                    background: "#003151",
                                 },
                                 "::-webkit-scrollbar-thumb": {
                                    background: "#888",
                                 },
                                 "::-webkit-scrollbar-thumb:hover": {
                                    background: "#555",
                                 },
                              }),
                           }}
                           value={
                              options && grantCurrent
                                 ? options.find(
                                    (o) => o.value === grantCurrent.id
                                 )
                                 : ""
                           }
                           options={options && options}
                           onChange={onChangedOption}
                        />

                        {grantCurrent && (
                           <button
                              className="btn btn-light me-1"
                              onClick={() => setShowEditGrant(true)}
                           >
                              <img
                                 style={{ width: "1.9em", height: "1.9em" }}
                                 src={Edit}
                                 alt="icon-Edit"
                              ></img>
                           </button>
                        )}

                        {grantCurrent && (
                           <button
                              className="btn btn-light"
                              onClick={() => setShowDeleteGrant(true)}
                           >
                              <img
                                 style={{ width: "1.9em", height: "1.9em" }}
                                 src={Delete}
                                 alt="icon-Delete"
                              ></img>
                           </button>
                        )}
                     </div>
                  )}
                  {/* </div> */}
                  <div className="contractamount contractamount--projects fw-normal d-flex justify-content-center me-sm-4 m-md-5 ms-lg-0 position-relative">
                     {grantCurrent && (
                        <p className="p-1 fs-5 text-white pt-2 fs-6 mar">
                           <div className="d-flex">Grant:</div>
                           <span className="contract--n contr p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent && ViewDollar(grantCurrent.amount)}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 fs-5 text-white pt-2 d-none d-xl-inline fs-6 mar">
                           <div className="d-flex">
                              <span className="d-md-none d-lg-inline">
                                 T.&nbsp;
                              </span>
                              Expenses:
                           </div>
                           <span className="contract--n expen p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_expense)}`}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 fs-5 text-white pt-2 d-none d-xl-block fs-6 mar">
                           <div className="d-flex">T. Assigned:</div>
                           <span className="contract--n p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_assigned)}`}
                           </span>
                        </p>
                     )}
                     {grantCurrent && (
                        <p className="p-1 fs-5 text-white pt-2 d-md-none d-lg-inline-block fs-6 mar">
                           <div className="d-flex">T. Remaining:</div>
                           <span className="contract--n rema p-2 fs-4 d-inline-block">
                              {" "}
                              {grantCurrent &&
                                 `${ViewDollar(grantCurrent.total_remaining)}`}
                           </span>
                        </p>
                     )}
                     {/* {grantCurrent &&
                        parseInt(grantCurrent.custom_percentage) < 70 && (
                           <div
                              className={
                                 "available  d-flex align-items-center p-1 mt-2 mt-md-0 px-md-4 fw-semibold"
                              }
                              id="budgetName"
                           >
                              <p className="m-0">
                                 {ViewDollar(grantCurrent.custom_remaining)}
                              </p>
                           </div>
                        )}

                     {grantCurrent &&
                        parseInt(grantCurrent.custom_percentage) > 70 &&
                        parseInt(grantCurrent.custom_percentage) < 99 && (
                           <div
                              className={
                                 "yellow-balance d-flex align-items-center p-1 mt-2 mt-md-0 px-md-4 fw-semibold"
                              }
                              style={{ color: "var(--secondary-color)" }}
                              id="budgetName"
                           >
                              <p className="m-0">
                                 {ViewDollar(grantCurrent.custom_remaining)}
                              </p>
                           </div>
                        )}

                     {grantCurrent &&
                        parseInt(grantCurrent.custom_percentage) >= 100 && (
                           <div
                              className={
                                 "red-balance d-flex align-items-center p-1 mt-2 mt-md-0 px-md-4 fw-semibold"
                              }
                              id="budgetName"
                           >
                              <p className="m-0">
                                 {ViewDollar(grantCurrent.custom_remaining)}
                              </p>
                           </div>
                        )} */}

                     {grantCurrent && (
                        <button
                           type="button"
                           className="addBtn addBtn--projects btn btn-lg fw-bold ms-md-4 m-auto"
                           onClick={handleShowModalOne}
                        // disabled={!grantCurrent ? true : false}
                        >
                           +
                        </button>
                     )}
                  </div>
               </div>
               <div className="dataTable dataTable-container mx-auto position-relative">
                  <div className="list-container">
                     <div className="rowItem row-container px-4 fw-bold">
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("object")}
                        >
                           {column1} {getSortingIndicator("object")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("function")}
                        >
                           {column2} {getSortingIndicator("function")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("description")}
                        >
                           {column3} {getSortingIndicator("description")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("department_program")}
                        >
                           {column4} {getSortingIndicator("department_program")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("contract_amount")}
                        >
                           {column5} {getSortingIndicator("contract_amount")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("expenses")}
                        >
                           {column6} {getSortingIndicator("expenses")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("remaining")}
                        >
                           {column7} {getSortingIndicator("remaining")}
                        </div>
                        <div
                           className="attribute attribute-header my-auto"
                           onClick={() => sorting("remaining_percentage")}
                        >
                           {column8}{" "}
                           {getSortingIndicator("remaining_percentage")}
                        </div>
                        <div className="attribute-update my-auto ps-2">
                           Update
                        </div>
                     </div>
                     {!grantCurrent && (
                        <div className="container mt-5">
                           <p
                              className="text-center fs-5 fw-semibold"
                              style={{ color: "var(--secondary-color)" }}
                           >
                              You have not selected (or created) any Grant
                              within this Folder yet
                           </p>
                        </div>
                     )}

                     {content && content.length === 0 && (
                        <div className="container mt-5">
                           <p
                              className="text-center fs-4 fw-bold"
                              style={{ color: "var(--secondary-color)" }}
                           >
                              NO BUDGETS
                           </p>
                        </div>
                     )}
                     {content &&
                        content.map((data, index) => (
                           <form
                              key={index}
                              className="rowItem row-container px-4 align-items-center"
                           >
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column1}>
                                    <input
                                       className="input"
                                       type="text"
                                       value={data.object}
                                       name="object"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <Link
                                    to={`/folder/${folder_id}/projects/contract/${data && data.id
                                       }`}
                                    className="button button-mobile d-flex align-items-center px-md-3"
                                 >
                                    <img
                                       src={Info}
                                       className="icon pe-2"
                                       alt="icon-folder"
                                    ></img>
                                    <div
                                       className="attribute attribute-object px-md-0"
                                       data-name={column1}
                                    >
                                       {data.object}
                                    </div>
                                 </Link>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column2}>
                                    <input
                                       className="input"
                                       type="text"
                                       value={data.function}
                                       name="function"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div data-name={column2} className="attribute">
                                    {data.function}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column2}>
                                    <textarea
                                       className="input"
                                       type="text"
                                       value={data.description}
                                       name="description"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div data-name={column2} className="attribute">
                                    {data.description}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div className="attribute" data-name={column3}>
                                    <input
                                       className="input"
                                       type="text"
                                       value={data.department_program}
                                       name="department_program"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div data-name={column3} className="attribute">
                                    {data.department_program}
                                 </div>
                              )}
                              {isEditing === index ? (
                                 <div
                                    data-name={column4}
                                    className="attribute position-relative"
                                 >
                                    <span className="input-dollar--sign">
                                       $
                                    </span>
                                    <input
                                       className="input input-dollar"
                                       type="number"
                                       value={data.contract_amount}
                                       name="contract_amount"
                                       onChange={(e) =>
                                          handleInputChange(e, index)
                                       }
                                    />
                                 </div>
                              ) : (
                                 <div data-name={column4} className="attribute">
                                    {ViewDollar(data.contract_amount)}
                                 </div>
                              )}
                              <div data-name={column5} className="attribute">
                                 {ViewDollar(data.expenses)}
                              </div>
                              <div data-name={column6} className="attribute">
                                 {ViewDollar(data.remaining)}
                              </div>
                              <div data-name={column7} className="attribute">
                                 <BadgeRemaining
                                    remaining={parseInt(
                                       data.remaining_percentage
                                    )}
                                 />
                              </div>
                              <div
                                 className="attribute d-md-flex ps-md-2"
                                 data-name="Update"
                              >
                                 <span className="d-flex">
                                    {isEditing === index ? (
                                       <button
                                          className="btn update-button--save d-flex p-1"
                                          onClick={(eve) =>
                                             handleSave(eve, index)
                                          }
                                       >
                                          <img
                                             className="icon-update"
                                             src={Save}
                                             alt="icon-update"
                                          ></img>
                                       </button>
                                    ) : (
                                       <button
                                          className="btn update-button d-flex p-1 align-items-center"
                                          onClick={(eve) =>
                                             handleEdit(eve, index)
                                          }
                                       >
                                          <img
                                             className="icon-update"
                                             alt="icon-update"
                                             src={Edit}
                                          ></img>
                                       </button>
                                    )}
                                    <button
                                       className="btn update-button d-flex p-1 ms-1 align-items-center"
                                       onClick={(eve) =>
                                          handleDelete(eve, index)
                                       }
                                    >
                                       <img
                                          className="icon-update"
                                          src={Delete}
                                          alt="icon-Delete"
                                       ></img>
                                    </button>
                                 </span>
                              </div>
                           </form>
                        ))}
                     {content && content.length > 0 && (
                        <Link
                           to={`/report/${grantCurrent && grantCurrent.id}`}
                           className="report-info text-center py-2 text-decoration-underline"
                        >
                           See the whole report here
                        </Link>
                     )}
                     {/* <div
                        className="separator"
                        style={{ background: "none" }}
                     ></div> */}
                  </div>
                  {/* <div className="row-container lastRowItem px-4 text-center fw-bold align-items-center">
                     {content && (
                        <>
                           <div className="attribute m-auto">
                              Total Expense
                              <br />
                              {grantCurrent &&
                                 ViewDollar(grantCurrent.total_expense)}
                           </div>
                           <div className="attribute percent">
                              Total Expense (%)
                              <br />
                              {grantCurrent &&
                                 parseFloat(
                                    grantCurrent.total_expense_percentage
                                 ).toFixed(2)}
                              %
                           </div>
                           <div className="attribute percent"></div>
                           <div className="attribute m-auto">
                              Total Assigned Remaining
                              <br />
                              {grantCurrent &&
                                 ViewDollar(grantCurrent.total_remaining)}
                           </div>
                           <div className="attribute percent">
                              Total A. Remaining (%)
                              <br />
                              {grantCurrent &&
                                 parseFloat(
                                    grantCurrent.total_remaining_percentage
                                 ).toFixed(2)}
                              %
                           </div>
                        </>
                     )}
                  </div> */}
               </div>
            </div>

            {/* Modal Delete Start */}
            <Modal
               centered
               size="lg"
               show={showDelete}
               onHide={() => setShowDelete(false)}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Warning </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <i className="m-0 fs-6">
                     {" "}
                     You are about to delete every contract and every
                     encumbrance.
                     <br />
                     Are you sure you want to delete this project ? <br />
                  </i>
                  <p className="m-0">
                     {idDelete && `Description: ${idDelete.description}`}
                  </p>
                  <p className="m-0 fs-6">
                     {idDelete &&
                        `Department or Program: ${idDelete.department_program}`}
                  </p>
                  <p className="m-0">
                     {idDelete &&
                        `Contract Amount: ${ViewDollar(
                           idDelete.contract_amount
                        )}`}
                  </p>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowDelete(false)}
                  >
                     Close
                  </Button>
                  <Button
                     variant="danger"
                     onClick={async () => {
                        await deleteBudgetService(idDelete.id);
                        setShowDelete(false);
                        getAllBudgetByGrant(grantCurrent.id);
                        dispatch(
                           setGrantCurrent(
                              await getGrantByIdService(grantCurrent.id)
                           )
                        );
                     }}
                  >
                     Delete
                  </Button>
               </Modal.Footer>
            </Modal>
            {/* Modal Delete End */}

            {/* Modal Delete Grant Start */}
            <Modal
               centered
               size="lg"
               show={showDeleteGrant}
               onHide={() => setShowDeleteGrant(false)}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Warning </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <i className="m-0 fs-6">
                     Are you sure you want to delete this GRANT? <br />
                  </i>
                  <p className="m-0">
                     {grantCurrent && `Name: ${grantCurrent.name}`}
                  </p>
                  <p className="m-0 fs-6">
                     {grantCurrent && `Amount: ${grantCurrent.amount}`}
                  </p>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => setShowDeleteGrant(false)}
                  >
                     Close
                  </Button>
                  <Button
                     variant="danger"
                     onClick={async () => {
                        await deleteGrantByIdService(grantCurrent.id);
                        setShowDeleteGrant(false);
                        getAllGrantsByFolder(folder_id);
                        dispatch(setGrantCurrent(undefined));
                        setContent(undefined);
                     }}
                  >
                     Delete
                  </Button>
               </Modal.Footer>
            </Modal>
            {/* Modal Delete GrantEnd */}

            <Modal
               centered
               show={modalState === "modalOne"}
               onHide={handleClose}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Create New Project</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {" "}
                  <FormProject
                     handleClose={handleClose}
                     grant_id={grantCurrent && grantCurrent.id}
                     getAllBudgetByGrant={getAllBudgetByGrant}
                     total_remaining={
                        grantCurrent && grantCurrent.total_remaining
                     }
                  />
               </Modal.Body>
            </Modal>
         </div>
         <Modal centered show={modalState === "modalTwo"} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Create Grant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <FormGrant
                  folder_id={folder_id && folder_id}
                  getAllGrantsByFolder={getAllGrantsByFolder}
                  handleClose={handleClose}
               />
            </Modal.Body>
         </Modal>

         <Modal centered show={showEditGrant} onHide={() => setShowEditGrant(false)}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Grant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <FormGrant
                  folder_id={folder_id && folder_id}
                  getAllGrantsByFolder={getAllGrantsByFolder}
                  handleClose={() => setShowEditGrant(false)}
                  grant={grantCurrent && grantCurrent}
               />
            </Modal.Body>

         </Modal>
      </>
   );
}

export { AdminProjectsTable };
