import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles/FormLogin.css";
import { useHistory } from "react-router-dom";
import {
   getCurrentUserService,
   getCurrentUserFolder,
} from "../../services/UsersServices";
import { useDispatch } from "react-redux";
import { setRole, setFolderId } from "../../redux/Reducers/roleSlice";
export default function FormLogin() {
   const [loading, setLoading] = useState(false);
   const [errorR, setErrorR] = useState(undefined);
   const history = useHistory();
   const dispatch = useDispatch();

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm();
   const onSubmit = async (data) => {
      setLoading(true);
      setErrorR(undefined);

      try {
         const result = await axios.post("/login", {
            user: data,
         });
         localStorage.setItem("token", result.headers.getAuthorization());
         setLoading(false);
         const token = localStorage.getItem("token");
         const user_result = await getCurrentUserService(token);
         const id = user_result.id;
         const role = user_result.role;
         dispatch(setRole(user_result.role));
         const folder = await getCurrentUserFolder(id);
         const folderId = folder.data;
         dispatch(setFolderId(folderId));
         if (typeof folderId === "number" || role === "Owner") {
            if (role === "Owner") {
               history.push(`/`);
            } else {
               history.push(`/folder/${folderId}/projects`);
            }
         } else {
            alert("Your user is not related to a folder");
         }
      } catch (error) {
         console.log(error);
         setLoading(false);
         setErrorR(error.response.data ? error.response.data : error.message);
      }
   };

   return (
      <>
         <h3 className="login-title d-flex text-center fw-bolder p-4 pt-md-4">
            Sign In
         </h3>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
               <input
                  type="email"
                  className="login-form ps-4 p-2 rounded-2 fs-5"
                  id="email"
                  name="email"
                  {...register("email", { required: "An email is required" })}
                  placeholder="Email"
               />
               {errors.email?.type === "required" && (
                  <div className="text-center form-text text-danger fw-semibold fs-7">
                     {" "}
                     {errors.email?.message}
                  </div>
               )}
            </div>
            <div className="mb-4">
               <input
                  type="password"
                  className="login-form ps-4 p-2 rounded-2 fs-5"
                  id="password"
                  name="password"
                  {...register("password", {
                     required: "A password is required",
                  })}
                  placeholder="Password"
               />
               {errors.password?.type === "required" && (
                  <div className="text-center form-text text-danger fw-semibold fs-7">
                     {" "}
                     {errors.password?.message}
                  </div>
               )}
            </div>

            <div className="mb-4">
               {loading && (
                  <div className="text-center">
                     <div
                        className="spinner-border"
                        style={{ color: "var(--secondary-color)" }}
                        role="status"
                     >
                        <span className="visually-hidden">Loading...</span>
                     </div>
                  </div>
               )}

               {errorR && (
                  <p className="text-center text-danger fw-semibold m-0 fs-7">
                     {" "}
                     {errorR}{" "}
                  </p>
               )}
            </div>
            <div className="pt-2">
               <button
                  type="submit"
                  className="login-button p-2 rounded-2 fs-5 fw-semibold"
               >
                  Login
               </button>
            </div>
            <div className="pt-4">
               <div className="text-white fw-semibold m-4">
                  {/* Forgot password? */}
               </div>
            </div>
         </form>
      </>
   );
}
