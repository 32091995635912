import axios from "axios";

export const getAllEncumbrancesByBudgetService = async (id_budget) => {
  try {
    const result = await axios.get(`/encumbrances/${id_budget}`);
    const newData = result.data.map((e) => {
      return {
        ...e,
        invoice_date: new Date(`${e.invoice_date}`)
          .toISOString()
          .substring(0, 10),
        encumbrance: parseFloat(e.encumbrance).toFixed(2),
        payment_amount: parseFloat(e.payment_amount).toFixed(2),
        outstanding_balance: parseFloat(e.outstanding_balance).toFixed(2),
      };
    });
    return newData;
  } catch (error) {}
};

export const createEncumbrancesServices = async (data) => {
  return await axios.post(`/encumbrances`, data);
};

export const updateEmcumbrancerService = async (data) => {
  return await axios.patch(`/encumbrances/${data.id}`, {
    encumbrance: data,
  });
};

export const getTotalEncumbranceService = async (id) => {
  try {
    const result = await axios.get(`encumbrances/tacontracts/${id}`);
    return result.data.encumbrances_total;
  } catch (error) {
    console.log(error);
  }
};

export const getTotalOutstandingService = async (id) => {
  try {
    const result = await axios.get(`encumbrances/tobalance/${id}`);
    return result.data.outstanding_total;
  } catch (error) {
    console.log(error);
  }
};

export const deleteEncumbrancesService = async (id) => {
  return await axios.delete(`encumbrances/${id}`);
};
