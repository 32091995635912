import { ViewDollar } from "../utils";

export default function OutstandingBalance({ value }) {
   value = parseInt(value)
   if (value === 0) {
      return (
         <div
            className="balance text-center green-balance position-md-absolute w-100 h-100 d-flex align-items-center"
            // style={{ top: 0, bottom: 0, right: 0, background: "red" }}
         >
            {ViewDollar(value)}
         </div>
      );
   }
   if (value < 0) {
      return (
         <div className="balance text-center red-balance  position-md-absolute w-100 h-100 d-flex align-items-center">
            {ViewDollar(value)}
         </div>
      );
   } else {
      return (
         <div className="balance text-center  yellow-balance position-md-absolute w-100 h-100 d-flex align-items-center">
            {ViewDollar(value)}
         </div>
      );
   }
}

export { OutstandingBalance };
