import React, { useEffect, useState } from "react";
import {
  associateUserToFolderService,
  deleteUserService,
} from "../services/UsersServices";
import { getAllFolderService } from "../services/FolderService";
import Select from "react-select";
import Delete from "../assets/delete.png";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function ListUserAccount({
  column1,
  column2,
  column3,
  column4,
  userAll,
  getAllUsers,
  sorting,
  getSortingIndicator,
}) {
  /* const [userAll, setUserAll] = useState(undefined); */
  const [options, setOptions] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(undefined);

  useEffect(() => {
    getAllFolder();
  }, []);

  const getAllFolder = async () => {
    /* setUserAll(await getAllUsersService()); */
    const o = await getAllFolderService();
    setOptions(
      o.map((s) => {
        return { value: s.id, label: s.name };
      })
    );
  };

  const associateUserToFolder = async (e, user_id) => {
    try {
      await associateUserToFolderService(user_id, e.value);
      getAllUsers();
    } catch (error) {
      console.log(error);
    }
  };

  /*  const handleEdit = (eve, index) => {
      eve.preventDefault();
      setIsEditing(index);
   }; */

  /* const handleSave = async (eve, index) => {
      eve.preventDefault();
      const editedData = userAll[index];
      // try {
      //    await updateUserService(editedData.id, editedData);
      //    setIsEditing(null);
      // } catch (error) {
      //    console.log(error);
      // }
   }; */

  const handleDelete = (eve, index) => {
    eve.preventDefault();
    console.log(eve);
    setShowDelete(true);
    setIdDelete(userAll[index]);
    /* setContent(content.filter((_, i) => i !== index)); */
  };

  return (
    <div className="list-container list-container--contract overflow-auto">
      <div className="rowItem row-container row-container--account ps-md-4 pe-2 fw-bold">
        <div
          className="attribute attribute-header"
          onClick={() => sorting("name")}
        >
          {column1} {getSortingIndicator("name") && getSortingIndicator("name")}
        </div>
        <div
          className="attribute attribute-header"
          onClick={() => sorting("email")}
        >
          {column2}{" "}
          {getSortingIndicator("email") && getSortingIndicator("email")}
        </div>
        <div
          className="attribute attribute-header"
          onClick={() => sorting("role")}
        >
          {column3} {getSortingIndicator("role") && getSortingIndicator("role")}
        </div>
        <div className="attribute attribute-update">{column4}</div>
        <div className="attribute attribute-update ps-md-2">Update</div>
      </div>
      {userAll &&
        userAll.map((u, index) => (
          <div
            key={index}
            className="rowItem row-container row-container--account ps-md-4 pe-2"
          >
            <div className="attribute" data-name={column1}>
              {u.name}
            </div>

            <div className="attribute attribute--account" data-name={column2}>
              {u.email}
            </div>

            <div className="attribute" data-name={column3}>
              {u.role}
            </div>

            <div
              className=" align-items-center justify-content-center d-md-flex"
              data-name={column4}
            >
              {u.role !== "Owner" && options && (
                <span className="folder-select">
                  <Select
                    autoComplete="off"
                    value={
                      u.folders[0] &&
                      options.find((o) => o.value === u.folders[0].id)
                    }
                    isDisabled={u.folders[0] ? true : false}
                    onChange={(e) => associateUserToFolder(e, u.id)}
                    options={options}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "11.1rem",
                        // minWidth: "1rem",
                        // width: "auto",
                      }),
                    }}
                  />
                </span>
              )}
              {/* <select defaultValue={null} 
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value={null} selected>
                                DOES NOT HAVE A FOLDER ASSOCIATED
                              </option>
                              {foldersAll  && foldersAll.length !==0 &&
                                foldersAll.map((f) => (
                                  <option selected={ u.folders[0].id === f.id} >
                                    {f.name}
                                  </option>
                                ))}
                            </select> */}
            </div>
            <div
              className="attribute d-md-flex ps-md-2 align-items-center"
              data-name="Update"
            >
              <span className="d-flex">
                {u.role !== "Owner" && (
                  <button
                    type="button"
                    // className="btn update-button d-flex p-1 align-items-center"
                    className="btn update-button d-flex p-1 ms-1 align-items-center"
                    onClick={(eve) => handleDelete(eve, index)}
                  >
                    <img
                      className="icon-update"
                      src={Delete}
                      alt="icon-update"
                    ></img>
                  </button>
                )}
              </span>
            </div>
            {/* <div>{u.email}</div> */}
            {/* <div>{u.name}</div> */}
            {/* <div>{u.role}</div> */}
          </div>
        ))}

      {/* Modal Delete Start */}
      <Modal
        centered
        size="lg"
        show={showDelete}
        onHide={() => setShowDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <i className="m-0 fs-6">
            <br />
            Are you sure you want to delete this USER? <br />
          </i>
          <p className="m-0">{idDelete && `Name: ${idDelete.name}`}</p>
          <p className="m-0 fs-6">{idDelete && `Email: ${idDelete.email}`}</p>
          <p className="m-0">{idDelete && `Role: ${idDelete.role}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await deleteUserService(idDelete.id);
                setShowDelete(false);
                getAllUsers();
              } catch (error) {}
              //getAllProjectsByBudget(folder_id);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End */}
    </div>
  );
}
