import "../styles/GoBackButton.css";
import gobackArrow from "../assets/back-arrow.png";
import {  useHistory } from "react-router-dom";

function GoBackButton() {
   const history = useHistory();
   return (
      <>
         {/* <div>{{ goBackBtn }}</div> */}
         <div className="d-none d-md-block">
            <button
               onClick={() => history.goBack()}
               className="goback__btn position-absolute d-flex fw-semibold fs-3"
            >
               <div className="goback__btn-circle">
                  <img className="goback__image" src={gobackArrow} alt="goback__image"></img>
               </div>
               Back
            </button>
         </div>
      </>
   );
}

export { GoBackButton };
