import { Layout } from "../components/Layout";
import { FoldersTable } from "../components/FoldersTable";
// import { FoldersContainer } from "../components/Owner/Folders";

function AdminFolders() {
   document.title = "Folders – Envision Education";
   return (
      <>
         <Layout
            goBackBtn={true}
            budgetBar={true}
            className="position-relative"
         >
            {/* <FoldersContainer /> */}
            <FoldersTable
               column1="Name of Department"
               column2="Total Amount"
               column3="Update"
            />
         </Layout>
      </>
   );
}

export { AdminFolders };
