import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../styles/UserCurrentCard.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setGrantCurrent, setRole } from "../redux/Reducers/roleSlice";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function UserCurrentCard() {
   const history = useHistory();
   const [userCurrent, setUserCurrent] = useState(undefined);

   const dispatch = useDispatch();
   useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
         validUser(token);
      } else {
         history.push("/login");
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const validUser = async (token) => {
      try {
         const result = await axios.get(`/current_user`, {
            headers: {
               "Content-Type": "application/json",
               Authorization: token,
            },
         });
         setUserCurrent(result.data);
      } catch (error) {
         console.log(error);
         history.push("/login");
      }
   };
   const onLogout = async () => {
      try {
         await axios.delete("/logout", {
            headers: {
               "Content-Type": "application/json",
               Authorization: localStorage.getItem("token"),
            },
         });
         history.push("/login");
         localStorage.removeItem("token");
         dispatch(setRole(""));
         dispatch(setGrantCurrent(undefined));
      } catch (error) {
         console.log(error);
      }
   };
   return (
      <div className="current-user d-flex px-5 justify-content-around align-items-center">
         <Link to={"/account"}>
            <p className="my-auto p-1">{userCurrent && userCurrent.name}</p>
         </Link>
         <p className="role my-auto">{userCurrent && userCurrent.role}</p>
         <button
            disabled={!userCurrent}
            onClick={onLogout}
            className="logout-btn fw-semibold"
         >
            Logout
         </button>
      </div>
   );
}

export { UserCurrentCard };
