export default function BadgeRemaining({ remaining }) {
   if (remaining >= 70) {
      return (
         <>
            <span className={"badge budget green-budget"}>
               {remaining.toLocaleString().substring(0, 3)}%
            </span>
         </>
      );
   }
   if (remaining >= 30) {
      return (
         <>
            <span className={"badge budget yellow-budget"}>
               {remaining.toLocaleString().substring(0, 3)}%
            </span>
         </>
      );
   } else {
      return (
         <>
            <span className={"badge budget red-budget"}>
               {remaining.toLocaleString().substring(0, 3)}%
            </span>
         </>
      );
   }
}
