import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { createFolderService } from "../../services/FolderService";
import CurrencyInput from "react-currency-input-field";
import { intlConfig } from "../../utils";

export default function FormFolder({ show, handleClose, getAllFolders }) {
   const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      if (await createFolderService(data)) {
         reset({ name: "", total_amount: "" });
         await getAllFolders();
         handleClose();
      }
   };

   return (
      <>
         <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Create New Folder</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)} className="text-dark">
               <Modal.Body>
                  <div className="mb-3">
                     <label for="name" className="form-label">
                        Folder Name
                     </label>
                     <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        aria-describedby="name"
                        placeholder=""
                        {...register("name", {
                           required: "Folder name is required",
                           pattern: {
                              value: /^(\S+\s*){1,7}$/,
                              message: "Maximum of 7 words allowed",
                           },
                        })}
                     />
                     {errors.name?.type === "required" && (
                        <small
                           id="name"
                           className="form-text fw-semibold text-danger"
                        >
                           {errors.name?.message}
                        </small>
                     )}
                     {errors.name?.type === "pattern" && (
                        <small
                           id="total_amount"
                           className="form-text fw-semibold text-danger"
                        >
                           {errors.name?.message}
                        </small>
                     )}
                  </div>

                  {errors.total_amount?.type === "required" && (
                     <small
                        id="total_amount"
                        className="form-text fw-semibold text-danger"
                     >
                        {errors.total_amount?.message}
                     </small>
                  )}

                  {errors.total_amount?.type === "pattern" && (
                     <small
                        id="total_amount"
                        className="form-text fw-semibold text-danger"
                     >
                        {errors.total_amount?.message}
                     </small>
                  )}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary">
                     Save
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </>
   );
}
